import React, { useState, useEffect, useRef } from "react";

import ArticuloService from "../services/articulo.service";
//import Table from 'react-bootstrap/Table'
import MUIDataTable from "mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Card from "./Card";
import CardMobile from "./CardMobile";
import { useMediaQuery } from "react-responsive";
import { Modal, Button, Image } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap/";

import Camera from "./cameraHandler/index";
import logo from "../assets/iconCamara32.png";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Lottie from "react-lottie";

import * as lapiz from "../assets/reloj.json";

import { EraserFill, FileEarmarkSpreadsheet } from "react-bootstrap-icons";

const dayjs = require("dayjs");

const Articulo = (tipo) => {
  const [content, setContent] = useState({ arts: [] });
  const [count, setCount] = useState({});
  const [oferta, setOferta] = useState(tipo.children);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");

  const containerRef = useRef(null);
  const [auxTextSearch, setAuxTextSearch] = useState("");

  const [ganancia, setGanancia] = useState("");

  // Modal //
  const [tituloModal, setTituloModal] = useState("");
  const [rowGuardada, setRowGuardada] = useState("");
  // Fin Modal //

  // Modal Camara//
  const [tituloModalCamara, setTituloModalCamara] = useState(
    "Escanear Código de Barras"
  );
  const [fullscreenCamara, setFullscreenCamara] = useState(true);
  const [showCamara, setShowCamara] = useState(false);
  // Fin Modal Camara//

  // Modal Card//
  const [tituloModalCard, setTituloModalCard] = useState("Art");
  const [fullscreenCard, setFullscreenCard] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [dataArt, setDataArt] = useState();
  // Fin Modal Camara//

  const [loadingExcel, setLoadingExcel] = useState(undefined);
  const [loadingData, setLoadingData] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  useEffect(() => {
    if (oferta === 0) {
      // Sin oferta
      ArticuloService.getTodos().then(
        (response) => {
          console.log(response.data);
          //response.data.map((articulo) => console.log(articulo));
          setContent({ arts: response.data });
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setContent(_content);
          setLoadingData(true);
        }
      );
    }
    if (oferta === 1) {
      // Con oferta
      ArticuloService.getOfertas().then(
        (response) => {
          console.log(response.data);
          //response.data.map((articulo) => console.log(articulo));
          setContent({ arts: response.data });
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          setContent(_content);
          setLoadingData(true);
        }
      );
    }
  }, []);

  const lapízOptions = {
    loop: true,
    autoplay: true,
    animationData: lapiz.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /*
  return (
    
    <div className="container">
      <header className="jumbotron">
          <h3>Articulos</h3>
          <Table responsive variant="dark">
            <thead>
              <tr>
                <th>Código</th>
                <th>Descripción</th>
                <th>Código de Barras</th>
                <th>IVA</th>
                <th>Precio Venta</th>
              </tr>
            </thead>
            <tbody>
                  {content.arts &&
                    content.arts.map((item) => (
                      <tr key={item.articulos_id}>
                        <td>{item.codigo}</td>
                        <td>{item.descri}</td>
                        <td>{item.codi_barra}</td>
                        <td>{item.IVA}</td>
                        <td>${item.PRE_VTA}</td>
                      </tr>
                    ))}
                </tbody>
          </Table>
      </header>
    </div>
  );
};
*/

  const columns = [
    {
      name: "articulos_id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codigo",
      label: "Código",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    {
      name: "descri",
      label: "Descripción",
      setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
    },
    { name: "codi_barra", label: "Barras" },
    { name: "IVA", label: "IVA" },
    { name: "PRE_VTA", label: "Precio Venta" },
    {
      name: "FEC_OBSERV",
      label: "Fecha Precio",
      options: {
        //display: 'excluded'
      },
    },
    { name: "FORMATO", label: "FORMATO" },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Artículos",
    filter: false,
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    download: false,
    print: false,
    searchText: search,
    fixedHeader: true,
    fixedSelectColumn: true,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
    if(expandedRows.data.length>1){
      let original = expandedRows.data;
      let aux = expandedRows.data.slice(dataIndex,-1);
      expandedRows.data = aux;
      setMostradas(aux);
      return true;
    }
    else{
      
    }
    //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
    return true;
    */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      if (isTabletOrMobile) {
        let textoSearch = containerRef.current.state.searchText;
        console.log(rowData);
        setTituloModal(rowData[2]);
        setRowGuardada(rowData);
        setAuxTextSearch(textoSearch);
        setShow(true);
      }
    },
    /*
  renderExpandableRow: (rowData, rowMeta) => {
    if(!isTabletOrMobile){
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan} >
            <Card>{rowData}</Card>
          </TableCell>
        </TableRow>
      );
    }
  },
  */
    renderExpandableRow: (rowData, rowMeta) => {
      if (!isTabletOrMobile) {
        let textoSearch = containerRef.current.state.searchText;
        setRowGuardada(rowData);
        setAuxTextSearch(textoSearch);
        setDataArt(rowData);
        setTituloModalCard(rowData[2]);
        setShowCard(true);
      }
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const actualizarPadre = (devuelto) => {
    setSearch(devuelto);
    setShowCamara(false);
  };

  const cerrarModal = () => {
    setSearch(auxTextSearch);
    setShow(false);
  };
  const cerrarModalCard = () => {
    setSearch(auxTextSearch);
    setShowCard(false);
  };
  const limpiarSearchBox = () => {
    //TODO Mejorar esto la segunda vez no lo borra
    //containerRef.current.state.searchText = "";
    setSearch("");
    setAuxTextSearch("");
  };
  //TODO agregar columna precio costo
  const exportarAExcel = async () => {
    console.log("Entra");
    let user = JSON.parse(localStorage.getItem("user"));
    setLoadingExcel(true);
    /*
  if(parseFloat(user.ganancia)>100){
      setGanancia(100/parseFloat(user.ganancia)); // Esto no anda, me saca solo la ganancia falta hacerle la suma
      }
    else{
      setGanancia((parseFloat(user.ganancia)/100)+1);
    }
    */
    try {
      const arts = await ArticuloService.getTodosSinObservacion();
      const rows = await arts.data;
      /*
      const rows = await arts.data.map(row => ({
        Código: row.Código,
        Descripción : row.Descripción,
        Código_de_Barras : row.Código_de_Barras,
        Formato: row.Formato,
        IVA : row.IVA,
        Precio_de_Venta : (parseFloat(user.ganancia)>100) ? 
           ((parseFloat(row.Precio_de_Costo) / (100/parseFloat(user.ganancia))) + parseFloat(row.Precio_de_Costo)).toFixed(2) :   (parseFloat(row.Precio_de_Costo * ((parseFloat(user.ganancia)/100)+1))).toFixed(2)// acá redondear y ponerle la ganancia
      }));
    */
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(rows);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      wb[0] = {
        // styling for all cells
        font: {
          name: "arial",
        },
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: "1", // any truthy value here
        },
        border: {
          right: {
            style: "thin",
            color: "0000FF",
          },
          left: {
            style: "thin",
            color: "0000FF",
          },
        },
      };
      const max_widthC1 = rows.reduce(
        (w, r) => Math.max(w, r.Código.length),
        0
      );
      const max_widthC2 = rows.reduce(
        (w, r) => Math.max(w, r.Descripción.length),
        5
      );
      const max_widthC3 = rows.reduce(
        (w, r) => Math.max(w, r.Código_de_Barras.length),
        0
      );
      ws["!cols"] = [
        { wch: max_widthC1 },
        { wch: max_widthC2 },
        { wch: max_widthC3 },
      ];

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        "Lista de Precios Karam " +
          dayjs().format("DD/MM/YYYY H:m:ss") +
          fileExtension
      );
      setLoadingExcel(false);
    } catch (error) {
      console.log(error);
    }
  };

  if (content.message !== "Su usuario no se encuentra habilitado") {
    return (
      <Container fluid>
        {loadingExcel ? (
          <Lottie options={lapízOptions} height={200} width={200} />
        ) : (
          <div>
            <Row className="d-flex justify-content-center">
              <Col>
                {!loadingData ? (
                  <Lottie options={lapízOptions} height={200} width={200} />
                ) : (
                  <div>
                    {isTabletOrMobile && oferta === 0 ? (
                      <Row className="d-flex justify-content-center">
                        <Col>
                          <Button
                            variant="light"
                            onClick={() => setShowCamara(true)}
                          >
                            <Image src={logo}></Image> Escanear
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="light"
                            onClick={() => limpiarSearchBox()}
                          >
                            <EraserFill size={30} /> Limpiar Campos
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      console.log("")
                    )}
                    <Row className="d-flex justify-content-center">
                      <Button variant="light" onClick={() => exportarAExcel()}>
                        <FileEarmarkSpreadsheet size={30} /> Exportar lista a
                        Excel
                      </Button>
                    </Row>
                    <Row className="d-flex justify-content-center">
                      <h6> </h6>
                    </Row>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Lista de Artículos"}
                      data={content.arts}
                      columns={columns}
                      options={options}
                      id="tabla"
                    ></MUIDataTable>
                  </div>
                )}
              </Col>
            </Row>
            <Modal
              show={show}
              fullscreen={fullscreen}
              onHide={() => setShow(false)}
            >
              <Modal.Header>
                <Modal.Title>{tituloModal}</Modal.Title>
                <Button variant="outline-danger" onClick={() => cerrarModal()}>
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-center">
                  <CardMobile>{rowGuardada}</CardMobile>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-danger" onClick={() => cerrarModal()}>
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showCamara}
              fullscreen={fullscreenCamara}
              onHide={() => setShowCamara(false)}
            >
              <Modal.Header>
                <Modal.Title>{tituloModalCamara}</Modal.Title>
                <Button
                  variant="outline-danger"
                  onClick={() => setShowCamara(false)}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-center">
                  <Col>
                    <Camera actualizarPadre={actualizarPadre}></Camera>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-danger"
                  onClick={() => setShowCamara(false)}
                >
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showCard}
              fullscreen={fullscreenCard}
              onHide={() => cerrarModalCard()}
            >
              <Modal.Header>
                <Modal.Title>{tituloModalCard}</Modal.Title>
                <Button
                  variant="outline-danger"
                  onClick={() => cerrarModalCard()}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-center">
                  <Col>
                    <Card>{dataArt}</Card>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-danger"
                  onClick={() => cerrarModalCard()}
                >
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          {<h2> Su usuario no se encuentra habilitado</h2>}
        </header>
      </div>
    );
  }
};

export default Articulo;
