import React, { useState, useEffect, useRef } from "react";

import PagosCuentasCorrientes from "../services/pagos_cuentas_corrientes.service";
import EventBus from "../common/EventBus";
import {
  Container,
  ListGroup,
  Row,
  Col,
  Button,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap/";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import UsuariosService from "../services/user.service";
import { useNavigate } from "react-router-dom";
const dayjs = require("dayjs");

const Cheques = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");

  const containerRef = useRef(null);
  const navigate = useNavigate();

  // Modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [modo, setModo] = useState("");
  // Fin Modal

  const [chequesAVencer, setChequesAVencer] = useState([]);
  const [historialDeCheques, setHistorialDeCheques] = useState([]);
  const [chequeElegido, setChequeElegido] = useState({});

  useEffect(() => {
    PagosCuentasCorrientes.getChequesAVencer().then(
      (response) => {
        setChequesAVencer(response.data);
        setResponseCode(response.status);
        PagosCuentasCorrientes.getHistorialDeCheques().then(
          (response) => {
            setHistorialDeCheques(response.data);
            setResponseCode(response.status);
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setContent(_content);

            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
          }
        );
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columnsChequesAVencer = [
    {
      name: "idPago",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Fecha",
      label: "Fecha Carga Pago",
      options: {
        display: "excluded",
      },
    },
    {
      name: "ChequeFechaPago",
      label: "Fecha Pago Cheque",
      options: {
        display: "excluded",
      },
    },
    {
      name: "ChequeFechaVencimiento",
      label: "Cheque Fecha Vencimiento",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            // Le agrego 3 hs por el uso horario, sino  se descajeta
            <Row style={{ paddingLeft: 30 }}>
              {dayjs(value).add(3, "hour").format("DD/MM/YYYY")}
            </Row>
          );
        },
      },
    },
    {
      name: "ChequeFechaVencimiento", // Lo traigo para tener el dato después
      label: "Cheque Fecha Vencimiento",
      options: {
        display: "excluded",
      },
    },
    {
      name: "ChequeNroCheque",
      label: "Nro Cheque",
      options: {},
    },
    {
      name: "ChequeMonto",
      label: "Cheque Monto",
      options: {},
    },
    {
      name: "ChequeBancoEmisor",
      label: "Cheque Banco",
      options: {
        //display: "excluded",
      },
    },
    {
      name: "ChequeDigital",
      label: "ChequeDigital",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Observaciones",
      label: "Observaciones",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cuentas_corriente.cliente.RazonSocial",
      label: "Razón Social",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cuentas_corriente.cliente.Cuit",
      label: "CUIT",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cuentas_corriente.cliente.Telefono",
      label: "Telefono",
      options: {
        display: "excluded",
      },
    },
    {
      name: "user.empleado.Nombre",
      label: "Nombre de empleado",
      options: {
        display: "excluded",
      },
    },
    {
      name: "user.empleado.Apellido",
      label: "Nombre de empleado",
      options: {
        display: "excluded",
      },
    },
    {
      name: "user.empleado.TelefonoCelular",
      label: "Telefono de empleado",
      options: {
        display: "excluded",
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Cheque",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      let aux = rowData;
      const nf = new Intl.NumberFormat("es-AR", {
        style: "decimal",
        currency: "ARS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      aux[6] = nf.format(rowData[6]);
      setChequeElegido(aux);
      setShow(true);
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const cerrarModal = () => {
    setShow(false);
  };

  if (responseCode === 200) {
    return (
      <Container flex>
        <h3>Cheques</h3>
        {content !== undefined && content !== "No token provided!" ? (
          <div>
            <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
              <Modal.Header>
                <Modal.Title>Cheque N°: {chequeElegido[5]}</Modal.Title>
                <Button variant="outline-danger" onClick={() => cerrarModal()}>
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                <h4>Datos cheque</h4>
                <ul>
                  <li>
                    Nro cheque: <b> {chequeElegido[5]} </b>
                  </li>
                  <li>
                    Fecha carga pago:{" "}
                    <b>
                      {dayjs(chequeElegido[1]).format("DD/MM/YYYY hh:mm:ss")}
                    </b>
                  </li>
                  <li>
                    Fecha pago cheque:{" "}
                    <b>
                      {dayjs(chequeElegido[2])
                        .add(3, "hour") // Le agrego 3 hs por el uso horario, sino  se descajeta
                        .format("DD/MM/YYYY")}
                    </b>
                  </li>
                  <li>
                    Fecha vencimiento cheque:{" "}
                    <b>
                      {" "}
                      {dayjs(chequeElegido[4])
                        .add(3, "hour") // Le agrego 3 hs por el uso horario, sino  se descajeta
                        .format("DD/MM/YYYY")}
                    </b>
                  </li>
                  <li>
                    Monto cheque: <b> $ {chequeElegido[6]}</b>
                  </li>
                  <li>
                    Banco emisor: <b> {chequeElegido[7]}</b>
                  </li>
                  <li>
                    Cheque digital:{" "}
                    <b> {chequeElegido[8] === 1 ? "Sí" : "No "}</b>
                  </li>
                  <li>
                    Observaciones: <b> {chequeElegido[9]} </b>
                  </li>
                </ul>
                <h4>Datos cliente</h4>
                <ul>
                  <li>
                    Razón social: <b>{chequeElegido[10]}</b>
                  </li>
                  <li>
                    CUIT: <b>{chequeElegido[11]}</b>
                  </li>
                  <li>
                    Teléfono: <b>{chequeElegido[12]}</b>
                  </li>
                </ul>
                <h4>Datos empleado</h4>
                <ul>
                  <li>
                    Nombre: <b>{chequeElegido[13] + " " + chequeElegido[14]}</b>
                  </li>
                  <li>
                    Teléfono: <b>{chequeElegido[15]}</b>
                  </li>
                </ul>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="outline-success" onClick={() => cerrarModal()}>
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <Row>
              <Col>
                <Tabs
                  defaultActiveKey="avencer"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={(k) => console.log("K:" + k)}
                >
                  <Tab eventKey="avencer" title="A Vencer">
                    <Row className="d-flex justify-content-center">
                      <h4>Cheques a vencer</h4>
                    </Row>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Cheques a vencer"}
                      data={chequesAVencer}
                      columns={columnsChequesAVencer}
                      options={options}
                      id="tablaChequesAVencer"
                    ></MUIDataTable>
                  </Tab>
                  <Tab eventKey="historial" title="Historial">
                    <h4>Historial de Cheques</h4>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Cheques a vencer"}
                      data={historialDeCheques}
                      columns={columnsChequesAVencer}
                      options={options}
                      id="tablaHistorialDeCheques"
                    ></MUIDataTable>
                    <Row className="d-flex justify-content-center"></Row>
                  </Tab>
                </Tabs>
              </Col>
            </Row>
          </div>
        ) : null}
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};
export default Cheques;
