import axios from "axios";
import authHeader from "./auth-header";
require("dotenv").config();

const API_URL = process.env.REACT_APP_URL + "/api/pcc/"; //http://localhost:8080/api/cli/";

const addPago = (nroCuenta, Pago) => {
  return axios.post(
    API_URL + nroCuenta + "/",
    { Pago },
    { headers: authHeader() }
  );
};

const addDescuento = (nroCuenta, Descuento) => {
  return axios.post(
    API_URL + "descuento/" + nroCuenta + "/",
    { Descuento },
    { headers: authHeader() }
  );
};

const addNDD = (nroCuenta, NDD) => {
  return axios.post(
    API_URL + "ndd/" + nroCuenta + "/",
    { NDD },
    { headers: authHeader() }
  );
};

const getPagos = async (idCuenta) => {
  const resultado = await axios.get(API_URL + "search/" + idCuenta, {
    headers: authHeader(),
  });
  return resultado;
};

const getPago = async (idPago) => {
  const resultado = await axios.get(API_URL + "searchPago/" + idPago, {
    headers: authHeader(),
  });
  return resultado;
};

const getHistorialDeCheques = async () => {
  const resultado = await axios.get(API_URL + "historialcheques/", {
    headers: authHeader(),
  });
  return resultado;
};

const getChequesAVencer = async () => {
  const resultado = await axios.get(API_URL + "chequesavencer/", {
    headers: authHeader(),
  });
  return resultado;
};

const PCCService = {
  addPago,
  addDescuento,
  addNDD,
  getPagos,
  getPago,
  getHistorialDeCheques,
  getChequesAVencer,
};

export default PCCService;
