import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Badge from "react-bootstrap/Badge";

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardModerator from "./components/BoardModerator";
import BoardAdmin from "./components/BoardAdmin";
import Ofertas from "./components/Ofertas";
import Cobros from "./components/Cobros";
import ControlFotos from "./components/ControlFotos";

import { useNavigate } from "react-router-dom";

import EventBus from "./common/EventBus";

//import logo from "./assets/logo.png";
import logo from "./assets/logoAux.png";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import PruebasCamara from "./components/PruebasCamara";
import Carrito from "./components/Carrito";
import Pedidos from "./components/Pedidos";
import ImprimirEtiquetas from "./components/ImprimirEtiquetas";
import PageNotFound from "./components/404";
import Permisos from "./components/Permisos";
import TablaPermisos from "./components/TablaPermisos";
import Usuarios from "./components/Usuarios";
import Cheques from "./components/Cheques";
import HistoricoCajas from "./components/HistoricoCajas";

import { Cart } from "react-bootstrap-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useCart } from "react-use-cart";

import { Modal, Button, Row, Col } from "react-bootstrap";

const App = () => {
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [showOfertas, setShowOfertas] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  const { totalItems, emptyCart, cartTotal } = useCart();

  // Modal Card//
  const [tituloModalCard, setTituloModalCard] = useState("Art");
  const [fullscreenCard, setFullscreenCard] = useState(true);
  const [showCard, setShowCard] = useState(false);
  const [dataArt, setDataArt] = useState();
  // Fin Modal Card//

  const navigate = useNavigate();

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
      if (user.oferta == 1) {
        setShowOfertas(true);
      }
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    navigate("/");
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
    setShowOfertas(false);
  };

  const limpiarCarrito = () => {
    setShowCard(true);
  };

  const cerrarModalCard = () => {
    setShowCard(false);
  };
  const cerrarModalCardVaciandoCarrito = () => {
    emptyCart();
    setShowCard(false);
  };

  //TODO ver si se puede cambiar las leyendas de la tabla de artículos
  //TODO Ver como agregar coordenadas del maps
  //TODO Traer solo los pedidos sin procesar, despúes hacer los incompletos
  //TODO ver si en el carrito deslogea la sesión

  return (
    <Container fluid>
      <Navbar collapseOnSelect bg="light" expand="lg">
        <Navbar.Brand href="#">
          <Link to={"/"} className="navbar-brand mb-0 h1">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top align-left"
              alt="José Karam y Otros S.H"
            />
          </Link>
          <Link to={"/"} className="navbar-brand mb-0 h1">
            {/* <container className="d-inline-block align-top">Karam</container> */}

            <container className="d-inline-block align-top">Etereo</container>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            {showModeratorBoard && (
              <li className="nav-item">
                <Link to={"/empleado"} className="nav-link">
                  Empleado
                </Link>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <Link to={"/admin"} className="nav-link">
                  Administrador
                </Link>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <Link to={"/articulos"} className="nav-link">
                  Articulos
                </Link>
              </li>
            )}
            {showOfertas && (
              <li className="nav-item">
                <Link to={"/ofertas"} className="nav-link">
                  Ofertas
                </Link>
              </li>
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/pedidos"} className="nav-link">
                  Mis Pedidos
                </Link>
              </li>
            )}
            {currentUser && (
              <li className="nav-item">
                <Link to={"/etiquetas"} className="nav-link">
                  Etiquetas
                </Link>
              </li>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            {currentUser ? (
              <div className="navbar-nav ">
                <NavDropdown
                  title={
                    <Badge pill bg="light">
                      <Cart size={20} /> ${cartTotal.toFixed(2)} - Cantidad de
                      Artículos: {totalItems.toFixed(2)}
                    </Badge>
                  }
                  id="navbarScrollingDropdown"
                >
                  <NavDropdown.Item href="/cart">Ver Carrito</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={limpiarCarrito}>
                    Limpiar Carrito
                  </NavDropdown.Item>
                </NavDropdown>

                <li className="nav-item">
                  <Link to={"/profile"} className="nav-link">
                    {currentUser.username}
                  </Link>
                </li>
                <li className="nav-item ">
                  <a href="/login" className="nav-link" onClick={logOut}>
                    Salir
                  </a>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ">
                <li className="nav-item">
                  <Link to={"/login"} className="nav-link">
                    <Button variant="primary">Ingresar</Button>{" "}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    <Button variant="warning">Registrarse</Button>{" "}
                  </Link>
                </li>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="container mt-3">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/articulos" element={<BoardUser />} />
          <Route path="/ofertas" element={<Ofertas />} />
          <Route path="/empleado" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          <Route path="/pruebas" element={<PruebasCamara />} />
          <Route path="/cart" element={<Carrito />} />
          <Route path="/pedidos" element={<Pedidos />} />
          <Route path="/etiquetas" element={<ImprimirEtiquetas />} />
          <Route path="/admin/permisos" element={<Permisos />} />
          <Route
            path="/admin/permisos/editar/:id"
            element={<TablaPermisos />}
          />
          <Route path="/admin/usuarios" element={<Usuarios />} />
          <Route path="/admin/cobros" element={<Cobros />} />
          <Route path="/admin/fotosarticulos" element={<ControlFotos />} />
          <Route path="/admin/cheques" element={<Cheques />} />
          <Route path="/admin/historicocajas" element={<HistoricoCajas />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Modal
        show={showCard}
        fullscreen={fullscreenCard}
        onHide={() => cerrarModalCard()}
      >
        <Modal.Header>
          <Modal.Title>Alerta</Modal.Title>
          <Button variant="outline-danger" onClick={() => cerrarModalCard()}>
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>Desea Vaciar el carrito?</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => cerrarModalCardVaciandoCarrito()}
          >
            Vaciar
          </Button>
          <Button variant="success" onClick={() => cerrarModalCard()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default App;
