import React, { useState, useEffect, useRef } from "react";

import ComprobantesService from "../services/comprobantes.service";
import PagosCuentasCorrientesService from "../services/pagos_cuentas_corrientes.service";
import CardMovimientos from "./CardMovimientos";

import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Card,
  Accordion,
  Tab,
  Tabs,
} from "react-bootstrap/";
import MUIDataTable from "mui-datatables";

import Lottie from "react-lottie";

import * as lapiz from "../assets/reloj.json";

import { MDBInputGroup, MDBInput, MDBCheckbox } from "mdb-react-ui-kit";

import { toast } from "react-toastify";

import Switch from "react-switch";

const dayjs = require("dayjs");

const CCCard = (rowData) => {
  const [content, setContent] = useState({ rowData: [] });
  const [idCuentaCorriente, setIdCuentaCorriente] = useState(
    rowData.children[0]
  );
  const [codigoCliente, setCodigoCliente] = useState(rowData.children[1]);
  const [CUIT, setCUIT] = useState(rowData.children[4]);
  const [diasAtraso, setDiasAtraso] = useState(rowData.children[7]);
  const [saldo, setSaldo] = useState(rowData.children[6]);
  const [fechaUltimoPago, setFechaUltimoPago] = useState(
    dayjs(rowData.children[5]).add(-3, "hour").format("DD/MM/YYYY")
  );
  const [montoMaximo, setMontoMaximo] = useState(rowData.children[8]);

  const containerRef = useRef(null);
  const [comprobantes, setComprobantes] = useState();
  const [pagos, setPagos] = useState();

  const [loadingData, setLoadingData] = useState(false);
  const [checkedChequeDigital, setCheckedChequeDigital] = useState(false);

  // Modal //
  const [show, setShow] = useState(false);
  const [tituloModal, setTituloModal] = useState("");
  const [rowGuardada, setRowGuardada] = useState("");
  // Fin Modal //

  // Opciones Select //
  const optionsSelect = [
    { value: "", text: "Elegir Opción ⬇️" },
    { value: "Cheque", text: "Cheque 🎫" },
    { value: "Efectivo", text: "Efectivo 💵" },
    { value: "Tarjeta", text: "Tarjeta 💳" },
    { value: "Transferencia", text: "Transferencia 💸" },
  ];
  const [selected, setSelected] = useState(optionsSelect[0].value);

  // Fin Opciones Select //

  // Opciones Select //
  const optionsSelectCuentasDestino = [
    { value: "", text: "Elegir Opción ⬇️" },
    { value: "Banco", text: "Banco 🏦" },
    { value: "MercadoPago", text: "Mercado Pago" },
  ];
  const [selectedCuentasDestino, setSelectedCuentasDestino] = useState(
    optionsSelectCuentasDestino[0].value
  );

  // Fin Opciones Select //

  // Opciones Select //
  const optionsSelectBancoEmisor = [
    { value: "", text: "Elegir Opción ⬇️" },
    { value: "BANCO SAN JUAN", text: "BANCO SAN JUAN" },
    { value: "BANCO DE LA NACIÓN", text: "BANCO DE LA NACIÓN" },
    { value: "BANCO MACRO", text: "BANCO MACRO" },
    { value: "BANCO GALICIA", text: "BANCO GALICIA" },
    { value: "BANCO BBVA", text: "BANCO BBVA" },
    { value: "BANCO SANTANDER", text: "BANCO SANTANDER" },
    { value: "BANCO PROVINCIA", text: "BANCO PROVINCIA" },
    { value: "BANCO INDUSTRIAL", text: "BANCO INDUSTRIAL" },
    { value: "BANCO PATAGONIA", text: "BANCO PATAGONIA" },
    { value: "BANCO CREDICOOP", text: "BANCO CREDICOOP" },
  ];
  const [selectedBancoEmisor, setSelectedBancoEmisor] = useState(
    optionsSelectBancoEmisor[0].value
  );

  // Fin Opciones Select //

  // Opciones Select //
  const optionsSelectTipoTarjeta = [
    { value: "", text: "Elegir Opción ⬇️" },
    { value: "CRÉDITO", text: "TARJETA DE CRÉDITO" },
    { value: "DÉBITO", text: "TARJETA DE DÉBITO" },
    { value: "MERCADO PAGO", text: "MERCADO PAGO" },
  ];
  const [selectedTipoTarjeta, setSelectedTipoTarjeta] = useState(
    optionsSelectTipoTarjeta[0].value
  );

  // Fin Opciones Select //

  // Opciones Select //
  const optionsSelectOpcionesDePago = [
    { value: "", text: "Elegir Tipo ⬇️" },
    { value: "PAGO", text: "Pago 💵" },
    { value: "DESCUENTO", text: "Descuento 💸" },
    { value: "DEBITO", text: "Nota de Débito 📄" },
  ];
  const [selectedOpcionesDePago, setSelectedOpcionesDePago] = useState(
    optionsSelectOpcionesDePago[0].value
  );
  // Fin Opciones Select //

  const [dateChequePago, setDateChequePago] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [dateChequeVencimiento, setDateChequeVencimiento] = useState(
    new Date()
  );
  const [dateEfectivo, setDateEfectivo] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [dateTarjeta, setDateTarjeta] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateTransferencia, setDateTransferencia] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [switchDescuentoChecked, setSwitchDescuentoChecked] = useState(false);

  // Modal Comprobante Pago CC//
  const [showComprobantePagoCC, setShowComprobantePagoCC] = useState(false);
  const [tituloModalComprobantePagoCC, setTituloModalComprobantePagoCC] =
    useState("");
  const [dataGetPago, setDataGetPago] = useState(false);
  // Fin Modal Comprobante Pago CC//

  const [permisoDescuento, setPermisoDescuento] = useState(false);
  const [permisoNotaDeDebito, setPermisoNotaDeDebito] = useState(false);

  const getData = () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.descuentoCC !== null) {
      let permiso = user.permisos;
      let permisoDescuento = permiso.find(
        (permiso) => permiso.Denominacion == "Hacer Descuentos"
      );
      let permisoNotaDeDebito = permiso.find(
        (permiso) => permiso.Denominacion == "Hacer Notas de Débito"
      );
      setPermisoDescuento(permisoDescuento.Habilitado);
      setPermisoNotaDeDebito(permisoNotaDeDebito.Habilitado);
    }
    ComprobantesService.getComprobantes(rowData.children[9]).then(
      (response) => {
        console.log(response.data);
        //response.data.map((articulo) => console.log(articulo));
        setComprobantes(response.data);
        setLoadingData(true);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setContent(_content);
        setLoadingData(true);
      }
    );
    PagosCuentasCorrientesService.getPagos(idCuentaCorriente).then(
      (response) => {
        console.log(response.data);
        //response.data.map((articulo) => console.log(articulo));
        setPagos(response.data);
        setLoadingData(true);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        //setContent(_content);
        setLoadingData(true);
      }
    );
  };

  useEffect(() => {
    getData();
  }, [rowData.children]);

  const columns = [
    {
      name: "idPp",
      label: "ID PP",
      options: {
        display: "excluded",
      },
    },
    {
      name: "idFactura",
      label: "ID FF",
      options: {
        display: "excluded",
      },
    },
    {
      name: "idDevoluciones",
      label: "ID NC",
      options: {
        display: "excluded",
      },
    },
    {
      name: "codigo",
      label: "Codigo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          let tipo = "";
          if (tableMeta.rowData[0] !== undefined) {
            let auxie = value.toString();
            return "PP - 0001-" + auxie.padStart(8, "0");
          }
          if (tableMeta.rowData[1] !== undefined) {
            if (tableMeta.rowData[6] === 1) {
              tipo = "FA";
            }
            if (tableMeta.rowData[6] === 6) {
              tipo = "FB";
            }
          }
          if (tableMeta.rowData[2] !== undefined) {
            if (tableMeta.rowData[6] === 1) {
              tipo = "NCA";
            }
            if (tableMeta.rowData[6] === 6) {
              tipo = "NCB";
            }
          }
          let aux = value.split("-");
          let pv = aux[0].padStart(4, "0");
          let nro = aux[1].padStart(8, "0");
          return tipo + " - " + pv + "-" + nro;
        },
      },
    },
    {
      name: "fecha",
      label: "Fecha",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format("DD/MM/YYYY H:m:ss");
        },
      },
    },
    {
      name: "importe",
      label: "Importe",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "tipoFactura",
      label: "tipoFactura",
      options: {
        display: "excluded",
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Comprobantes",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
                      if(expandedRows.data.length>1){
                        let original = expandedRows.data;
                        let aux = expandedRows.data.slice(dataIndex,-1);
                        expandedRows.data = aux;
                        setMostradas(aux);
                        return true;
                      }
                      else{
                        
                      }
                      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
                      return true;
                      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      console.log(rowData);
      let pp = rowData[3].includes("PP");
      let ff = rowData[3].includes("F");
      let nc = rowData[3].includes("NC");
      if (pp === true) {
        console.log("PP:" + rowData[0]);
      }
      if (ff === true) {
        console.log("FF:" + rowData[1]);
      }
      if (nc === true) {
        console.log("NC:" + rowData[2]);
      }
      setTituloModal(rowData[3]);
      setRowGuardada(rowData);
      setShow(true);
    },
    /*
                    renderExpandableRow: (rowData, rowMeta) => {
                      if(!isTabletOrMobile){
                        const colSpan = rowData.length + 1;
                        return (
                          <TableRow>
                            <TableCell colSpan={colSpan} >
                              <Card>{rowData}</Card>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    },
                    */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const columnsPagos = [
    {
      name: "idPago",
      label: "ID Pago",
      options: {
        display: "excluded",
      },
    },
    { name: "Tipo", label: "Tipo" },
    {
      name: "Fecha",
      label: "Fecha de Carga",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
    {
      name: "ChequeFechaPago",
      label: "Fecha Cheque",
      options: {
        display: "excluded",
      },
    },
    {
      name: "EfectivoFechaPago",
      label: "Fecha Efectivo",
      options: {
        display: "excluded",
      },
    },
    {
      name: "TarjetaFechaPago",
      label: "Fecha Tarjeta",
      options: {
        display: "excluded",
      },
    },
    {
      name: "TransferenciaFechaPago",
      label: "Fecha Transferencia",
      options: {
        display: "excluded",
      },
    },
    {
      name: "",
      label: "Fecha de Pago",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          // Le agrego .add(3,'hour') porque sino me lo toma como un dia antes
          switch (tableMeta.rowData[1]) {
            case "Cheque":
              return dayjs(tableMeta.rowData[3])
                .add(3, "hour")
                .format("DD/MM/YYYY");
              break;
            case "Efectivo":
              return dayjs(tableMeta.rowData[4])
                .add(3, "hour")
                .format("DD/MM/YYYY");
              break;
            case "Tarjeta":
              return dayjs(tableMeta.rowData[5])
                .add(3, "hour")
                .format("DD/MM/YYYY");
              break;
            case "Transferencia":
              return dayjs(tableMeta.rowData[6])
                .add(3, "hour")
                .format("DD/MM/YYYY");
              break;
            case "Descuento":
              return "";
              break;
          }
          return dayjs(value).format("DD/MM/YYYY");
        },
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return "$ " + nf.format(value);
        },
      },
    },
  ];
  const optionsPagos = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Comprobantes",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
                      if(expandedRows.data.length>1){
                        let original = expandedRows.data;
                        let aux = expandedRows.data.slice(dataIndex,-1);
                        expandedRows.data = aux;
                        setMostradas(aux);
                        return true;
                      }
                      else{
                        
                      }
                      //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
                      return true;
                      */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: (rowData) => {
      PagosCuentasCorrientesService.getPago(rowData[0]).then(
        (response) => {
          console.log(response.data);
          let aux = response.data;
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          aux.EfectivoMonto = nf.format(aux.EfectivoMonto);
          aux.ChequeMonto = nf.format(aux.ChequeMonto);
          aux.TarjetaMonto = nf.format(aux.TarjetaMonto);
          aux.TransferenciaMonto = nf.format(aux.TransferenciaMonto);
          aux.Total = nf.format(aux.Total);

          setDataGetPago(aux);
          setTituloModalComprobantePagoCC(response.data.idPago);
          setShowComprobantePagoCC(true);
          //response.data.map((articulo) => console.log(articulo));
          setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          //setContent(_content);
          setLoadingData(true);
        }
      );
      //setTituloModal(rowData[3]);
      //setRowGuardada(rowData);
      //setShow(true);
    },
    /*
                    renderExpandableRow: (rowData, rowMeta) => {
                      if(!isTabletOrMobile){
                        const colSpan = rowData.length + 1;
                        return (
                          <TableRow>
                            <TableCell colSpan={colSpan} >
                              <Card>{rowData}</Card>
                            </TableCell>
                          </TableRow>
                        );
                      }
                    },
                    */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };
  const lapízOptions = {
    loop: true,
    autoplay: true,
    animationData: lapiz.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  function handleShow(breakpoint) {
    setShow(true);
  }
  function handleShowCerrar() {
    setShow(false);
  }

  const handleChange = (event) => {
    console.log(event.target.value);
    setSelected(event.target.value);
  };

  const handleChangeCuentasDestino = (event) => {
    console.log(event.target.value);
    setSelectedCuentasDestino(event.target.value);
  };

  const handleChangeBancoEmisor = (event) => {
    console.log(event.target.value);
    setSelectedBancoEmisor(event.target.value);
  };

  const handleChangeOpcionesDePago = (event) => {
    console.log(event.target.value);
    setSelectedOpcionesDePago(event.target.value);
  };

  const handleFechaVencimientoCheque = (event) => {
    let aux1 = dayjs(event.target.value);
    let aux2 = dayjs(dateChequePago);
    if (
      dayjs(dateChequePago)
        .add(1, "month")
        .isBefore(dayjs(event.target.value), "day")
    ) {
      document.getElementById("dateChequeVencimiento").value = "";
      setDateChequeVencimiento("");
      toast.error(
        "La fecha de vencimiento del cheque no puede ser menor a la fecha de pago.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        }
      );
    } else {
      setDateChequeVencimiento(event.target.value);
    }
  };

  const handleFechaPagoCheque = (event) => {
    if (
      dayjs(event.target.value).isAfter(dayjs(dateChequeVencimiento), "day")
    ) {
      document.getElementById("dateChequePago").value = "";
      setDateChequePago("");
      toast.error(
        "La fecha de vencimiento del cheque no puede ser menor a la fecha de pago.",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        }
      );
    } else {
      setDateChequePago(event.target.value);
    }
  };

  const handleChangeTipoTarjeta = (event) => {
    console.log(event.target.value);
    setSelectedTipoTarjeta(event.target.value);
  };

  const handleFechaPagoEfectivo = (event) => {
    if (dayjs(event.target.value).isAfter(dayjs(), "day")) {
      document.getElementById("datepicEfectivo").value = "";
      setDateEfectivo("");
      toast.error("La fecha de Pago no puede ser mayor a la fecha actual.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
    } else {
      setDateEfectivo(event.target.value);
    }
  };

  const handleFechaPagoTarjeta = (event) => {
    if (dayjs(event.target.value).isAfter(dayjs(), "day")) {
      document.getElementById("dateTarjeta").value = "";
      setDateTarjeta("");
      toast.error("La fecha de Pago no puede ser mayor a la fecha actual.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
    } else {
      setDateTarjeta(event.target.value);
    }
  };

  const handleFechaPagoTransferencia = (event) => {
    if (dayjs(event.target.value).isAfter(dayjs(), "day")) {
      document.getElementById("dateTransferencia").value = "";
      setDateTransferencia("");
      toast.error("La fecha de Pago no puede ser mayor a la fecha actual.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
    } else {
      setDateTransferencia(event.target.value);
    }
  };

  function cargarPago() {
    setLoadingData(false);
    let pago = {};
    let descuento = {};
    if (selected === "") {
      console.log("Vacio, dar Error");
      toast.error("Debe seleccionar una forma de pago.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      setLoadingData(true);
      return;
    }
    if (selected === "Cheque") {
      console.log("Cheque");
      const fechaVencimientoCheque = document.getElementById(
        "dateChequeVencimiento"
      ).value;
      const fechaPagoCheque = document.getElementById("dateChequePago").value;
      const bancoEmisor = selectedBancoEmisor;
      const nroCheque = document.getElementById("nroCheque").value;
      const montoCheque = document.getElementById("montoCheque").value;
      const observaciones = document.getElementById("observaciones").value;
      if (parseFloat(document.getElementById("montoCheque").value) <= 0) {
        toast.error("El monto debe ser mayor a cero.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      }
      if (
        fechaVencimientoCheque === "" ||
        fechaPagoCheque === "" ||
        bancoEmisor === "" ||
        nroCheque === "" ||
        montoCheque === ""
      ) {
        toast.error("Debe completar todos los campos.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      } else {
        pago = {
          Tipo: "Cheque",
          Total: montoCheque,
          Fecha: dayjs(),
          ChequeBancoEmisor: bancoEmisor,
          ChequeFechaVencimiento: fechaVencimientoCheque,
          ChequeFechaPago: fechaPagoCheque,
          ChequeNroCheque: nroCheque,
          ChequeMonto: montoCheque,
          TarjetaFechaPago: null,
          TarjetaNroComprobante: "",
          TarjetaMonto: 0,
          TransferenciaFechaPago: null,
          TransferenciaCuentaDestino: "",
          TransferenciaNroComprobante: "",
          TransferenciaMonto: 0,
          EfectivoFechaPago: null,
          EfectivoMonto: null,
          Observaciones: observaciones,
          ChequeDigital: checkedChequeDigital,
        };
      }
    }
    if (selected === "Efectivo") {
      console.log("Efectivo");
      const fechaPagoEfectivo =
        document.getElementById("datepicEfectivo").value;
      const montoEfectivo = document.getElementById("montoEfectivo").value;
      const observaciones = document.getElementById("observaciones").value;
      if (parseFloat(document.getElementById("montoEfectivo").value) <= 0) {
        toast.error("El monto debe ser mayor a cero.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      }
      if (fechaPagoEfectivo === "" || montoEfectivo === "") {
        toast.error("Debe completar todos los campos.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      } else {
        pago = {
          Tipo: "Efectivo",
          Total: montoEfectivo,
          Fecha: dayjs(),
          ChequeBancoEmisor: "",
          ChequeFechaVencimiento: null,
          ChequeNroCheque: "",
          ChequeMonto: 0,
          TarjetaFechaPago: null,
          TarjetaNroComprobante: "",
          TarjetaMonto: 0,
          TransferenciaFechaPago: null,
          TransferenciaCuentaDestino: "",
          TransferenciaNroComprobante: "",
          TransferenciaMonto: 0,
          EfectivoFechaPago: fechaPagoEfectivo,
          EfectivoMonto: montoEfectivo,
          Observaciones: observaciones,
        };
      }
    }
    if (selected === "Tarjeta") {
      console.log("Tarjeta");
      const fechaPagoTarjeta = document.getElementById("dateTarjeta").value;
      const montoTarjeta = document.getElementById("montoTarjeta").value;
      const comprobanteTarjeta =
        document.getElementById("comprobanteTarjeta").value;
      const observaciones = document.getElementById("observaciones").value;
      const tipoDeTarjeta = selectedTipoTarjeta;
      if (parseFloat(document.getElementById("montoTarjeta").value) <= 0) {
        toast.error("El monto debe ser mayor a cero.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      }
      if (
        fechaPagoTarjeta === "" ||
        montoTarjeta === "" ||
        tipoDeTarjeta === ""
      ) {
        toast.error("Debe completar todos los campos.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      } else {
        pago = {
          Tipo: "Tarjeta",
          Total: montoTarjeta,
          Fecha: dayjs(),
          ChequeBancoEmisor: "",
          ChequeFechaVencimiento: null,
          ChequeNroCheque: "",
          ChequeMonto: 0,
          TarjetaFechaPago: fechaPagoTarjeta,
          TarjetaNroComprobante: comprobanteTarjeta,
          TarjetaMonto: montoTarjeta,
          TransferenciaFechaPago: null,
          TransferenciaCuentaDestino: "",
          TransferenciaNroComprobante: "",
          TransferenciaMonto: 0,
          EfectivoFechaPago: null,
          EfectivoMonto: 0,
          Observaciones: observaciones,
          TipoDeTarjeta: tipoDeTarjeta,
        };
      }
    }
    if (selected === "Transferencia") {
      console.log("Transferencia");
      const fechaTransferencia =
        document.getElementById("dateTransferencia").value;
      const montoTransferencia =
        document.getElementById("montoTransferencia").value;
      const comprobanteTransferencia = document.getElementById(
        "comprobanteTransferencia"
      ).value;
      const observaciones = document.getElementById("observaciones").value;
      const cuentaDestino = selectedCuentasDestino;
      if (
        parseFloat(document.getElementById("montoTransferencia").value) <= 0
      ) {
        toast.error("El monto debe ser mayor a cero.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      }
      if (
        fechaTransferencia === "" ||
        montoTransferencia === "" ||
        cuentaDestino === ""
      ) {
        toast.error("Debe completar todos los campos.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        setLoadingData(true);
        return;
      } else {
        pago = {
          Tipo: "Transferencia",
          Total: montoTransferencia,
          Fecha: dayjs(),
          ChequeBancoEmisor: "",
          ChequeFechaVencimiento: null,
          ChequeNroCheque: "",
          ChequeMonto: 0,
          TarjetaFechaPago: null,
          TarjetaNroComprobante: 0,
          TarjetaMonto: 0,
          TransferenciaFechaPago: fechaTransferencia,
          TransferenciaCuentaDestino: cuentaDestino,
          TransferenciaNroComprobante: comprobanteTransferencia,
          TransferenciaMonto: montoTransferencia,
          EfectivoFechaPago: null,
          EfectivoMonto: 0,
          Observaciones: observaciones,
        };
      }
    }
    PagosCuentasCorrientesService.addPago(idCuentaCorriente, pago).then(
      (response) => {
        setLoadingData(true);
        console.log(response.data);
        toast.success(<div>Pago cargado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        limpiarCamposPagos();
        if (response.data.saldoNuevo !== undefined) {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setSaldo(nf.format(response.data.saldoNuevo));
        }
        if (response.data.fechaUltimoPago !== undefined) {
          setFechaUltimoPago(response.data.fechaUltimoPago);
        }
        PagosCuentasCorrientesService.getPagos(idCuentaCorriente).then(
          (response) => {
            console.log(response.data);
            //response.data.map((articulo) => console.log(articulo));
            setPagos(response.data);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setContent(_content);
          }
        );
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        toast.error(
          "Ocurrió un error, intente nuevamente. De persistir el error contáctese con soporte.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    );
  }

  function cargarDescuento() {
    setLoadingData(false);
    const descuentoTotal = parseFloat(
      document.getElementById("montoDescuento").value
    );
    const observacionesDescuento = document.getElementById(
      "observacionesDescuento"
    ).value;
    if (document.getElementById("montoDescuento").value === "") {
      toast.error("Debe completar todos los campos.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      setLoadingData(true);
      return;
    }
    if (parseFloat(document.getElementById("montoDescuento").value) <= 0) {
      setLoadingData(true);
      toast.error("El monto del descuento debe ser mayor a cero.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      return;
    }
    const descuento = {
      Total: descuentoTotal,
      ObservacionesDescuento: observacionesDescuento,
    };
    PagosCuentasCorrientesService.addDescuento(
      idCuentaCorriente,
      descuento
    ).then(
      (response) => {
        setLoadingData(true);
        console.log(response.data);
        toast.success(<div>Descuento cargado correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        if (response.data.saldoNuevo !== undefined) {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setSaldo(nf.format(response.data.saldoNuevo));
        }
        PagosCuentasCorrientesService.getPagos(idCuentaCorriente).then(
          (response) => {
            console.log(response.data);
            //response.data.map((articulo) => console.log(articulo));
            setPagos(response.data);
            setLoadingData(true);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setContent(_content);
            setLoadingData(true);
          }
        );
        limpiarCamposDescuento();
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (error.response.status === 403) {
          setLoadingData(true);
          toast.error(
            "El usuario no tiene permisos para realizar esta acción",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setLoadingData(true);
          return;
        }
        setLoadingData(true);
        toast.error(
          "Ocurrió un error, intente nuevamente. De persistir el error contáctese con soporte.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    );
  }

  function cargarNDD() {
    setLoadingData(false);
    const montoNDDTotal = parseFloat(document.getElementById("montoNDD").value);
    const observacionesNDD = document.getElementById("observacionesNDD").value;
    if (document.getElementById("montoNDD").value === "") {
      toast.error("Debe completar todos los campos.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      setLoadingData(true);
      return;
    }
    if (parseFloat(document.getElementById("montoNDD").value) <= 0) {
      setLoadingData(true);
      toast.error("El monto de la nota de débito debe ser mayor a cero.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      return;
    }
    const NDD = { Total: montoNDDTotal, ObservacionesNDD: observacionesNDD };
    PagosCuentasCorrientesService.addNDD(idCuentaCorriente, NDD).then(
      (response) => {
        setLoadingData(true);
        console.log(response.data);
        toast.success(<div>Nota de débito cargada correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        if (response.data.saldoNuevo !== undefined) {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          setSaldo(nf.format(response.data.saldoNuevo));
        }
        PagosCuentasCorrientesService.getPagos(idCuentaCorriente).then(
          (response) => {
            console.log(response.data);
            //response.data.map((articulo) => console.log(articulo));
            setPagos(response.data);
            setLoadingData(true);
          },
          (error) => {
            const _content =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            //setContent(_content);
            setLoadingData(true);
          }
        );
        limpiarCamposNDD();
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (error.response.status === 403) {
          setLoadingData(true);
          toast.error(
            "El usuario no tiene permisos para realizar esta acción",
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          return;
        }
        setLoadingData(true);
        toast.error(
          "Ocurrió un error, intente nuevamente. De persistir el error contáctese con soporte.",
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    );
  }

  function limpiarCamposPagos() {
    if (selected === "Efectivo") {
      //document.getElementById("datepicEfectivo").value = "";
      document.getElementById("montoEfectivo").value = "";
      setDateEfectivo(dayjs().format("YYYY-MM-DD"));
    }
    if (selected === "Cheque") {
      //document.getElementById("dateChequePago").value = "";
      setDateChequePago(dayjs().format("YYYY-MM-DD"));
      document.getElementById("dateChequeVencimiento").value = "";
      setDateChequeVencimiento("");
      document.getElementById("nroCheque").value = "";
      document.getElementById("montoCheque").value = "";
      setSelectedBancoEmisor(optionsSelectBancoEmisor[0].value);
      setCheckedChequeDigital(false);
    }
    if (selected === "Tarjeta") {
      //document.getElementById("dateTarjeta").value = "";
      setDateTarjeta(dayjs().format("YYYY-MM-DD"));
      document.getElementById("montoTarjeta").value = "";
      document.getElementById("comprobanteTarjeta").value = "";
      setSelectedTipoTarjeta(optionsSelectTipoTarjeta[0].value);
    }
    if (selected === "Transferencia") {
      //document.getElementById("dateTransferencia").value = "";
      setDateTransferencia(dayjs().format("YYYY-MM-DD"));
      document.getElementById("montoTransferencia").value = "";
      document.getElementById("comprobanteTransferencia").value = "";
      setSelectedCuentasDestino(optionsSelectCuentasDestino[0].value);
    }
    document.getElementById("observaciones").value = "";
    if (switchDescuentoChecked === true) {
      document.getElementById("montoDescuento").value = "";
      document.getElementById("observacionesDescuento").value = "";
      setSwitchDescuentoChecked(false);
    }
  }

  function limpiarCamposDescuento() {
    document.getElementById("montoDescuento").value = "";
    document.getElementById("observacionesDescuento").value = "";
  }
  function limpiarCamposNDD() {
    document.getElementById("montoNDD").value = "";
    document.getElementById("observacionesNDD").value = "";
  }

  const handleSwitchDescuentoChange = (checked) => {
    setSwitchDescuentoChecked(checked);
  };

  function handleShowComprobantePagoCC(breakpoint) {
    setShowComprobantePagoCC(true);
  }
  function handleShowCerrarGetComprobantePagoCC() {
    setShowComprobantePagoCC(false);
  }

  return (
    <Container flex>
      <Col sm={8}>
        <Row>
          <ul>
            <li>
              Código Cliente: <b>{codigoCliente}</b>
            </li>
            <li>
              CUIT: <b>{CUIT}</b>
            </li>
            <li>
              Fecha Ultimo Pago: <b>{fechaUltimoPago}</b>
            </li>
            <li>
              Días de Atraso: <b>{diasAtraso}</b>
            </li>
            <li>
              Saldo: <b>${saldo}</b>
            </li>
            <li>
              Monto Máximo de deuda: <b>${montoMaximo}</b>
            </li>
          </ul>
        </Row>
      </Col>
      <Row>
        <Col>
          <Tabs defaultActiveKey="facturas" id="tabOpciones" fill>
            <Tab eventKey="facturas" title="Facturas">
              {!loadingData ? (
                <Lottie options={lapízOptions} height={200} width={200} />
              ) : (
                <div>
                  <MUIDataTable
                    ref={containerRef}
                    title={"Lista de Comprobantes"}
                    data={comprobantes}
                    columns={columns}
                    options={options}
                    id="tabla"
                  ></MUIDataTable>
                </div>
              )}
            </Tab>
            <Tab eventKey="historialPagos" title="Historial">
              {!loadingData ? (
                <Lottie options={lapízOptions} height={200} width={200} />
              ) : (
                <MUIDataTable
                  ref={containerRef}
                  title={"Lista de Pagos"}
                  data={pagos}
                  columns={columnsPagos}
                  options={optionsPagos}
                  id="tablaPagos"
                ></MUIDataTable>
              )}
            </Tab>
            <Tab eventKey="cargarPago" title="Cargar">
              {!loadingData ? (
                <Lottie options={lapízOptions} height={200} width={200} />
              ) : (
                <div>
                  <Row
                    className="justify-content-md-center"
                    style={{ paddingTop: "8px" }}
                  >
                    Tipo de Operación:
                    <select
                      value={selectedOpcionesDePago}
                      onChange={handleChangeOpcionesDePago}
                      style={{ marginLeft: "8px" }}
                    >
                      {optionsSelectOpcionesDePago.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  </Row>
                  <Col>
                    {selectedOpcionesDePago === "PAGO" ? (
                      <Row
                        className="justify-content-md-center"
                        style={{ paddingTop: "8px" }}
                      >
                        <Row
                          className="justify-content-md-center"
                          style={{ paddingTop: "8px" }}
                        >
                          Forma de Pago
                          <select
                            value={selected}
                            onChange={handleChange}
                            style={{ marginLeft: "8px" }}
                          >
                            {optionsSelect.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </Row>
                        {selected === "Cheque" ? (
                          <div style={{ paddingTop: "8px" }}>
                            Banco Emisor
                            <select
                              value={selectedBancoEmisor}
                              onChange={handleChangeBancoEmisor}
                              style={{ marginLeft: "8px", marginTop: "8px" }}
                            >
                              {optionsSelectBancoEmisor.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.text}
                                </option>
                              ))}
                            </select>
                            <div>
                              Fecha Pago
                              <Form.Control
                                type="date"
                                name="datechequePAgo"
                                id="dateChequePago"
                                placeholder="DateRange"
                                value={dateChequePago}
                                onChange={handleFechaPagoCheque}
                              />
                            </div>
                            <div>
                              Fecha Vencimiento
                              <Form.Control
                                type="date"
                                name="datechequeVencimiento"
                                id="dateChequeVencimiento"
                                placeholder="DateRange"
                                value={dateChequeVencimiento}
                                onChange={handleFechaVencimientoCheque}
                              />
                            </div>
                            <div>
                              <MDBInputGroup
                                className="mb-3"
                                textBefore="N°"
                                style={{ paddingTop: "10px" }}
                              >
                                <MDBInput
                                  placeholder="Nro Cheque"
                                  id="nroCheque"
                                  type="number"
                                  style={{ maxWidth: "190px" }}
                                />
                              </MDBInputGroup>
                              <MDBInputGroup className="mb-3" textBefore="$">
                                <MDBInput
                                  placeholder="Monto"
                                  id="montoCheque"
                                  type="number"
                                  style={{ maxWidth: "200px" }}
                                />
                              </MDBInputGroup>
                              <MDBCheckbox
                                checked={checkedChequeDigital}
                                onChange={() =>
                                  setCheckedChequeDigital(!checkedChequeDigital)
                                }
                                id="checkChequeDigital"
                                label="Cheque Digital"
                              />
                            </div>
                          </div>
                        ) : null}
                        {selected === "Tarjeta" ? (
                          <div style={{ paddingTop: "8px" }}>
                            Fecha de Pago
                            <Form.Control
                              type="date"
                              name="datepic"
                              id="dateTarjeta"
                              placeholder="DateRange"
                              value={dateTarjeta}
                              onChange={handleFechaPagoTarjeta}
                            />
                            <div>
                              <div style={{ paddingTop: "8px" }}>
                                Tipo de Tarjeta:
                                <select
                                  value={selectedTipoTarjeta}
                                  onChange={handleChangeTipoTarjeta}
                                  style={{ marginLeft: "8px" }}
                                >
                                  {optionsSelectTipoTarjeta.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.text}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <MDBInputGroup
                                className="mb-3"
                                textBefore="N°"
                                style={{ paddingTop: "8px" }}
                              >
                                <MDBInput
                                  placeholder="Nro Comprobante"
                                  id="comprobanteTarjeta"
                                  type="number"
                                  style={{ maxWidth: "180px" }}
                                />
                              </MDBInputGroup>
                              <MDBInputGroup className="mb-3" textBefore="$">
                                <MDBInput
                                  placeholder="Monto"
                                  id="montoTarjeta"
                                  type="number"
                                  style={{ maxWidth: "190px" }}
                                />
                              </MDBInputGroup>
                            </div>
                          </div>
                        ) : null}
                        {selected === "Transferencia" ? (
                          <div style={{ paddingTop: "8px" }}>
                            Fecha de Pago
                            <Form.Control
                              type="date"
                              name="datepic"
                              placeholder="DateRange"
                              id="dateTransferencia"
                              value={dateTransferencia}
                              onChange={handleFechaPagoTransferencia}
                            />
                            <div style={{ paddingTop: "8px" }}>
                              Cuenta Destino:
                              <select
                                value={selectedCuentasDestino}
                                onChange={handleChangeCuentasDestino}
                                style={{ marginLeft: "8px" }}
                              >
                                {optionsSelectCuentasDestino.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.text}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div>
                              <MDBInputGroup
                                className="mb-3"
                                textBefore="N°"
                                style={{ paddingTop: "8px" }}
                              >
                                <MDBInput
                                  placeholder="Nro Comprobante"
                                  id="comprobanteTransferencia"
                                  type="number"
                                  style={{ maxWidth: "180px" }}
                                />
                              </MDBInputGroup>
                              <MDBInputGroup className="mb-3" textBefore="$">
                                <MDBInput
                                  placeholder="Monto"
                                  id="montoTransferencia"
                                  type="number"
                                  style={{ maxWidth: "190px" }}
                                />
                              </MDBInputGroup>
                            </div>
                          </div>
                        ) : null}
                        {selected === "Efectivo" ? (
                          <div style={{ paddingTop: "8px" }}>
                            Fecha de Pago
                            <Form.Control
                              type="date"
                              id="datepicEfectivo"
                              placeholder="DateRange"
                              value={dateEfectivo}
                              onChange={handleFechaPagoEfectivo}
                            />
                            <div style={{ paddingTop: "8px" }}>
                              <MDBInputGroup className="mb-3" textBefore="$">
                                <MDBInput
                                  placeholder="Monto"
                                  id="montoEfectivo"
                                  type="number"
                                  style={{ maxWidth: "190px" }}
                                />
                              </MDBInputGroup>
                            </div>
                          </div>
                        ) : null}
                        <textarea
                          class="form-control"
                          placeholder="Observaciones"
                          id="observaciones"
                          rows="4"
                          style={{ marginTop: "8px" }}
                        ></textarea>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="info"
                            onClick={() => cargarPago()}
                            style={{ marginTop: "8px" }}
                          >
                            Cargar Pago
                          </Button>
                        </div>
                      </Row>
                    ) : selectedOpcionesDePago === "DESCUENTO" ? (
                      <div>
                        {permisoDescuento === 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Row
                              className="justify-content-md-center"
                              style={{ paddingTop: "8px" }}
                            >
                              <div>
                                <Row className="justify-content-md-center">
                                  <div>
                                    <MDBInputGroup
                                      className="mb-3"
                                      textBefore="$"
                                    >
                                      <MDBInput
                                        placeholder="Monto Descuento"
                                        id="montoDescuento"
                                        type="number"
                                      />
                                    </MDBInputGroup>
                                    <textarea
                                      class="form-control"
                                      placeholder="Observaciones Descuento"
                                      id="observacionesDescuento"
                                      rows="4"
                                    ></textarea>
                                  </div>
                                </Row>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                    paddingTop: "8px",
                                  }}
                                >
                                  <Button
                                    variant="info"
                                    onClick={() => cargarDescuento()}
                                  >
                                    Cargar Descuento
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <h4>No tiene permisos para realizar descuentos.</h4>
                          </div>
                        )}
                      </div>
                    ) : selectedOpcionesDePago === "DEBITO" ? (
                      <div>
                        {permisoNotaDeDebito === 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Row
                              className="justify-content-md-center"
                              style={{ paddingTop: "8px" }}
                            >
                              <div>
                                <Row className="justify-content-md-center">
                                  <div>
                                    <MDBInputGroup
                                      className="mb-3"
                                      textBefore="$"
                                    >
                                      <MDBInput
                                        placeholder="Monto Nota de Débito"
                                        id="montoNDD"
                                        type="number"
                                      />
                                    </MDBInputGroup>
                                    <textarea
                                      class="form-control"
                                      placeholder="Observaciones Notas De Débito"
                                      id="observacionesNDD"
                                      rows="4"
                                    ></textarea>
                                  </div>
                                </Row>
                                <Row
                                  className="justify-content-md-center"
                                  style={{ paddingTop: "8px" }}
                                >
                                  <Button
                                    variant="info"
                                    onClick={() => cargarNDD()}
                                  >
                                    Cargar Nota de Débito
                                  </Button>
                                </Row>
                              </div>
                            </Row>
                          </div>
                        ) : (
                          <div>
                            <h4>
                              No tiene permisos para realizar notas de débito.
                            </h4>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Col>
                </div>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
      <Modal show={show} fullscreen={true} onHide={() => handleShow()}>
        <Modal.Header>
          <Modal.Title>Comprobante: {tituloModal}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>
              <CardMovimientos>{rowGuardada}</CardMovimientos>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={() => handleShowCerrar()}>
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showComprobantePagoCC}
        fullscreen={true}
        onHide={() => handleShowComprobantePagoCC()}
      >
        <Modal.Header>
          <Modal.Title>Comprobante: {tituloModalComprobantePagoCC}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="d-flex justify-content-center">
            <Col>
              {dataGetPago.Tipo === "Cheque" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Cheque Fecha Pago:{" "}
                      <b>
                        {dayjs(dataGetPago.ChequeFechaPago).format("DD/MM/YY")}
                      </b>
                    </li>
                    <li>
                      Cheque Banco Emisor:{" "}
                      <b>{dataGetPago.ChequeBancoEmisor}</b>
                    </li>
                    <li>
                      Cheque Fecha Vencimiento:{" "}
                      <b>
                        {dayjs(dataGetPago.ChequeFechaVencimiento).format(
                          "DD/MM/YY"
                        )}
                      </b>
                    </li>
                    <li>
                      Número de Cheque: <b>{dataGetPago.ChequeNroCheque}</b>
                    </li>
                    <li>
                      Cheque Monto: <b>$ {dataGetPago.ChequeMonto}</b>
                    </li>
                    <li>
                      Cheque Digital:{" "}
                      <b>{dataGetPago.ChequeDigital ? "Si" : "No"}</b>
                    </li>
                    <li>
                      Observaciones: <b>{dataGetPago.Observaciones}</b>
                    </li>
                  </ul>
                </div>
              ) : dataGetPago.Tipo === "Efectivo" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Efectivo Fecha Pago:{" "}
                      <b>
                        {dayjs(dataGetPago.EfectivoFechaPago).format(
                          "DD/MM/YY"
                        )}
                      </b>
                    </li>
                    <li>
                      Efectivo Monto: <b>$ {dataGetPago.EfectivoMonto}</b>
                    </li>
                    <li>
                      Observaciones: <b>{dataGetPago.Observaciones}</b>
                    </li>
                  </ul>
                </div>
              ) : dataGetPago.Tipo === "Tarjeta" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Tarjeta Fecha Pago:{" "}
                      <b>
                        {dayjs(dataGetPago.TarjetaFechaPago).format("DD/MM/YY")}
                      </b>
                    </li>
                    <li>
                      Tarjeta Nro Comprobante:{" "}
                      <b>{dataGetPago.TarjetaNroComprobante}</b>
                    </li>
                    <li>
                      Tarjeta Monto: <b>$ {dataGetPago.TarjetaMonto}</b>
                    </li>
                    <li>
                      Tipo de Tarjeta: <b>{dataGetPago.TipoDeTarjeta}</b>
                    </li>
                    <li>
                      Observaciones: <b>{dataGetPago.Observaciones}</b>
                    </li>
                  </ul>
                </div>
              ) : dataGetPago.Tipo === "Transferencia" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Transferencia Fecha Pago:{" "}
                      <b>
                        {dayjs(dataGetPago.TransferenciaFechaPago).format(
                          "DD/MM/YY"
                        )}
                      </b>
                    </li>
                    <li>
                      Transferencia Cuenta Destino:{" "}
                      <b>{dataGetPago.TransferenciaCuentaDestino}</b>
                    </li>
                    <li>
                      Transferencia Nro Comprobante:{" "}
                      <b>{dataGetPago.TransferenciaNroComprobante}</b>
                    </li>
                    <li>
                      Transferencia Monto:{" "}
                      <b>$ {dataGetPago.TransferenciaMonto}</b>
                    </li>
                    <li>
                      Observaciones: <b>{dataGetPago.Observaciones}</b>
                    </li>
                  </ul>
                </div>
              ) : dataGetPago.Tipo === "Descuento" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Descuento Monto: <b>$ {dataGetPago.Total}</b>
                    </li>
                    <li>
                      Observaciones Descuento:{" "}
                      <b>{dataGetPago.ObservacionesDescuento}</b>
                    </li>
                  </ul>
                </div>
              ) : dataGetPago.Tipo === "Nota De Débito" ? (
                <div>
                  <ul>
                    <li>
                      Fecha:{" "}
                      <b>
                        {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                      </b>
                    </li>
                    <li>
                      Nota De Débito Monto: <b>$ {dataGetPago.Total}</b>
                    </li>
                    <li>
                      Observaciones Nota de débito:{" "}
                      <b>{dataGetPago.ObservacionesNDD}</b>
                    </li>
                  </ul>
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => handleShowCerrarGetComprobantePagoCC()}
          >
            Salir
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
export default CCCard;
