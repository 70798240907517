import React, { useState, useEffect, useRef } from "react";

import CajasService from "../services/cajas.service";
import EventBus from "../common/EventBus";
import {
  Container,
  ListGroup,
  Row,
  Col,
  Button,
  Modal,
  Tab,
  Tabs,
} from "react-bootstrap/";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import UsuariosService from "../services/user.service";
import PagosCuentasCorrientesService from "../services/pagos_cuentas_corrientes.service";
import { useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import "./styleTabs.css";

import Lottie from "react-lottie";

import * as lapiz from "../assets/reloj.json";

const dayjs = require("dayjs");

const Cobros = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");

  const containerRef = useRef(null);
  const navigate = useNavigate();

  // Modal
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [modo, setModo] = useState("");
  // Fin Modal

  // Modal Pregunta//
  const [tituloModalPregunta, setTituloModalPregunta] = useState("Atención");
  const [fullscreenPregunta, setFullscreenPregunta] = useState(true);
  const [showPregunta, setShowPregunta] = useState(false);
  // Fin Modal Pregunta//

  // Modal InformeCaja//
  const [tituloModalInformeCaja, setTituloModalInformeCaja] =
    useState("Informe de Caja");
  const [fullscreenInformeCaja, setFullscreenInformeCaja] = useState(true);
  const [showInformeCaja, setShowInformeCaja] = useState(false);
  // Fin Modal InformeCaja//

  const [empleados, setEmpleados] = useState([]);
  const [dataElegido, setDataElegido] = useState([]);
  const [cajaActual, setCajaActual] = useState([]);
  const [historialDeCajas, setHistorialDeCajas] = useState([]);
  const [informeCaja, setInformeCaja] = useState([]);
  const [historialPagosCaja, setHistorialPagosCaja] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  // Modal Comprobante Pago CC//
  const [showComprobantePagoCC, setShowComprobantePagoCC] = useState(false);
  const [tituloModalComprobantePagoCC, setTituloModalComprobantePagoCC] =
    useState("");
  const [dataGetPago, setDataGetPago] = useState(false);
  // Fin Modal Comprobante Pago CC//

  const lapízOptions = {
    loop: true,
    autoplay: true,
    animationData: lapiz.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    setLoadingData(true);
    UsuariosService.getAllUsers().then(
      (response) => {
        setEmpleados(response.data.empleados);
        setResponseCode(response.status);
        setLoadingData(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setContent(_content);
        setLoadingData(false);
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columnsEmpleados = [
    {
      name: "id",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "username",
      label: "Nombre de Usuario",
    },
    {
      name: "empleado.Nombre",
      label: "Nombre Empleado",
      options: {
        display: "excluded",
      },
    },
    {
      name: "empleado.Apellido",
      label: "Nombre Empleado",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 30 }}>
              {" "}
              {tableMeta.rowData[2] + " " + value}
            </Row>
          );
        },
      },
    },
    {
      name: "empleado.CodigoVendedor",
      label: "Codigo de Vendedor",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
  ];

  const columnsHistorialDeCajas = [
    {
      name: "idCaja",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "FechaCierre",
      label: "Fecha de Cierre",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 30 }}>
              {dayjs(value).format("DD/MM/YYYY HH:mm")}
            </Row>
          );
        },
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "ChequeMonto",
      label: "ChequeMonto",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "TarjetaMonto",
      label: "TarjetaMonto",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "TransferenciaMonto",
      label: "TransferenciaMonto",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "EfectivoMonto",
      label: "EfectivoMonto",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "Observaciones",
      label: "Observaciones",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Total", // Lo traigo de nuevo para que se guarde sin el formato y poder mostrarlo después
      label: "Total",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return nf.format(value);
        },
      },
    },
    {
      name: "FechaCierre", // Lo traigo de nuevo para que se guarde sin el formato y poder mostrarlo después
      label: "Fecha de Cierre",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
  ];

  const columnsPagosCajaActual = [
    {
      name: "idPago",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cuentas_corriente.cliente.RazonSocial",
      label: "Razón Social",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Tipo",
      label: "Tipo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          return (
            <Row style={{ paddingLeft: 30 }}>{"$" + nf.format(value)}</Row>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Empleado",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      setDataElegido(rowData);
      setLoadingData(true);
      const responseCaja = await CajasService.searchCaja(rowData[0]);
      let auxCajaActual = responseCaja.data;
      const nf = new Intl.NumberFormat("es-AR", {
        style: "decimal",
        currency: "ARS",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      auxCajaActual.montos.montoCheque = nf.format(
        auxCajaActual.montos.montoCheque
      );
      auxCajaActual.montos.montoEfectivo = nf.format(
        auxCajaActual.montos.montoEfectivo
      );
      auxCajaActual.montos.montoTarjeta = nf.format(
        auxCajaActual.montos.montoTarjeta
      );
      auxCajaActual.montos.montoTransferencia = nf.format(
        auxCajaActual.montos.montoTransferencia
      );
      auxCajaActual.montos.montoTotal = nf.format(
        auxCajaActual.montos.montoTotal
      );
      setCajaActual(auxCajaActual);
      const responseHistorial = await CajasService.getHistorialCajas(
        rowData[0]
      );
      setLoadingData(false);
      setHistorialDeCajas(responseHistorial.data);
      setShow(true);
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const optionsHistorialDeCajas = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar Caja",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      setInformeCaja(rowData);
      setLoadingData(true);
      CajasService.getHistorialPagosCaja(rowData[0]).then((response) => {
        console.log(response);
        setHistorialPagosCaja(response.data);
        setLoadingData(false);
        setShowInformeCaja(true);
      });
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const optionsPagosCajaActual = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar pago",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    elevation: 0,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      setLoadingData(true);
      PagosCuentasCorrientesService.getPago(rowData[0]).then(
        (response) => {
          console.log(response.data);
          let auxDataGetPago = response.data;
          const nf = new Intl.NumberFormat("es-AR", {
            style: "decimal",
            currency: "ARS",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
          auxDataGetPago.ChequeMonto = nf.format(auxDataGetPago.ChequeMonto);
          auxDataGetPago.EfectivoMonto = nf.format(
            auxDataGetPago.EfectivoMonto
          );
          auxDataGetPago.TarjetaMonto = nf.format(auxDataGetPago.TarjetaMonto);
          auxDataGetPago.TransferenciaMonto = nf.format(
            auxDataGetPago.TransferenciaMonto
          );
          auxDataGetPago.Total = nf.format(auxDataGetPago.Total);
          setDataGetPago(auxDataGetPago);
          setLoadingData(false);
          setTituloModalComprobantePagoCC(response.data.idPago);
          setShowComprobantePagoCC(true);
          //response.data.map((articulo) => console.log(articulo));
          //setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          setLoadingData(false);
          //setContent(_content);
          //setLoadingData(true);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const cerrarModal = () => {
    setShow(false);
  };

  const cerrarModalPregunta = () => {
    setShowPregunta(false);
  };

  const cerrarModalInformeCaja = () => {
    setShowInformeCaja(false);
  };

  const cerrarModalCierraCaja = () => {
    if (cajaActual.montos.montoTotal === 0) {
      toast.error(<div>No se puede cerrar una caja sin movimientos</div>, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        whiteSpace: "pre-line",
      });
      setShowPregunta(false);
      return;
    }
    let observacion = document.getElementById("observacion").value;
    setLoadingData(true);
    CajasService.cerrarCaja(dataElegido[0], observacion).then(
      async (response) => {
        console.log(response);
        toast.success(<div>Caja cerrada correctamente</div>, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          whiteSpace: "pre-line",
        });
        const responseCaja = await CajasService.searchCaja(dataElegido[0]);
        setCajaActual(responseCaja.data);
        const responseHistorial = await CajasService.getHistorialCajas(
          dataElegido[0]
        );
        setLoadingData(false);
        setHistorialDeCajas(responseHistorial.data);
        setShowPregunta(false);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoadingData(false);
        toast.error(
          <div>
            {error.message}. De persistir el error contáctese con soporte
          </div>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            whiteSpace: "pre-line",
          }
        );
        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };
  const chequesClicked = () => {
    navigate("/admin/cheques");
  };

  const historicoCajasClicked = () => {
    navigate("/admin/historicocajas");
  };

  function handleShowComprobantePagoCC(breakpoint) {
    setShowComprobantePagoCC(true);
  }
  function handleShowCerrarGetComprobantePagoCC() {
    setShowComprobantePagoCC(false);
  }

  if (responseCode === 200) {
    return (
      <Container flex>
        {loadingData ? (
          <Lottie options={lapízOptions} height={200} width={200} />
        ) : (
          <div>
            <Modal
              show={showComprobantePagoCC}
              fullscreen={true}
              onHide={() => handleShowComprobantePagoCC()}
            >
              <Modal.Header>
                <Modal.Title>
                  Comprobante: {tituloModalComprobantePagoCC}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="d-flex justify-content-center">
                  <Col>
                    {dataGetPago.Tipo === "Cheque" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Cheque Fecha Pago:{" "}
                            <b>
                              {dayjs(dataGetPago.ChequeFechaPago).format(
                                "DD/MM/YY"
                              )}
                            </b>
                          </li>
                          <li>
                            Cheque Banco Emisor:{" "}
                            <b>{dataGetPago.ChequeBancoEmisor}</b>
                          </li>
                          <li>
                            Cheque Fecha Vencimiento:{" "}
                            <b>
                              {dayjs(dataGetPago.ChequeFechaVencimiento).format(
                                "DD/MM/YY"
                              )}
                            </b>
                          </li>
                          <li>
                            Número de Cheque:{" "}
                            <b>{dataGetPago.ChequeNroCheque}</b>
                          </li>
                          <li>
                            Cheque Monto: <b>$ {dataGetPago.ChequeMonto}</b>
                          </li>
                          <li>
                            Cheque Digital:{" "}
                            <b>{dataGetPago.ChequeDigital ? "Si" : "No"}</b>
                          </li>
                          <li>
                            Observaciones: <b>{dataGetPago.Observaciones}</b>
                          </li>
                        </ul>
                      </div>
                    ) : dataGetPago.Tipo === "Efectivo" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Efectivo Fecha Pago:{" "}
                            <b>
                              {dayjs(dataGetPago.EfectivoFechaPago).format(
                                "DD/MM/YY"
                              )}
                            </b>
                          </li>
                          <li>
                            Efectivo Monto: <b>$ {dataGetPago.EfectivoMonto}</b>
                          </li>
                          <li>
                            Observaciones: <b>{dataGetPago.Observaciones}</b>
                          </li>
                        </ul>
                      </div>
                    ) : dataGetPago.Tipo === "Tarjeta" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Tarjeta Fecha Pago:{" "}
                            <b>
                              {dayjs(dataGetPago.TarjetaFechaPago).format(
                                "DD/MM/YY"
                              )}
                            </b>
                          </li>
                          <li>
                            Tarjeta Nro Comprobante:{" "}
                            <b>{dataGetPago.TarjetaNroComprobante}</b>
                          </li>
                          <li>
                            Tarjeta Monto: <b>$ {dataGetPago.TarjetaMonto}</b>
                          </li>
                          <li>
                            Tipo de Tarjeta: <b>{dataGetPago.TipoDeTarjeta}</b>
                          </li>
                          <li>
                            Observaciones: <b>{dataGetPago.Observaciones}</b>
                          </li>
                        </ul>
                      </div>
                    ) : dataGetPago.Tipo === "Transferencia" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Transferencia Fecha Pago:{" "}
                            <b>
                              {dayjs(dataGetPago.TransferenciaFechaPago).format(
                                "DD/MM/YY"
                              )}
                            </b>
                          </li>
                          <li>
                            Transferencia Cuenta Destino:{" "}
                            <b>{dataGetPago.TransferenciaCuentaDestino}</b>
                          </li>
                          <li>
                            Transferencia Nro Comprobante:{" "}
                            <b>{dataGetPago.TransferenciaNroComprobante}</b>
                          </li>
                          <li>
                            Transferencia Monto:{" "}
                            <b>$ {dataGetPago.TransferenciaMonto}</b>
                          </li>
                          <li>
                            Observaciones: <b>{dataGetPago.Observaciones}</b>
                          </li>
                        </ul>
                      </div>
                    ) : dataGetPago.Tipo === "Descuento" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Descuento Monto: <b>$ {dataGetPago.Total}</b>
                          </li>
                          <li>
                            Observaciones Descuento:{" "}
                            <b>{dataGetPago.ObservacionesDescuento}</b>
                          </li>
                        </ul>
                      </div>
                    ) : dataGetPago.Tipo === "Nota De Débito" ? (
                      <div>
                        <ul>
                          <li>
                            Fecha:{" "}
                            <b>
                              {dayjs(dataGetPago.Fecha).format(
                                "DD/MM/YY HH:mm:ss"
                              )}
                            </b>
                          </li>
                          <li>
                            Nota De Débito Monto: <b>$ {dataGetPago.Total}</b>
                          </li>
                          <li>
                            Observaciones Nota de débito:{" "}
                            <b>{dataGetPago.ObservacionesNDD}</b>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-danger"
                  onClick={() => handleShowCerrarGetComprobantePagoCC()}
                >
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showPregunta}
              fullscreen={true}
              onHide={() => setShowPregunta(false)}
            >
              <Modal.Header>
                <Modal.Title>{tituloModalPregunta}</Modal.Title>
                <Button
                  variant="outline-danger"
                  onClick={() => cerrarModalPregunta()}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>Desea cerrar la caja?</Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-danger"
                  onClick={() => cerrarModalCierraCaja()}
                >
                  Cerrar Caja
                </Button>
                <Button
                  variant="outline-success"
                  onClick={() => cerrarModalPregunta()}
                >
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={showInformeCaja}
              fullscreen={true}
              onHide={() => setShowInformeCaja(false)}
            >
              <Modal.Header>
                <Modal.Title>
                  {tituloModalInformeCaja + " N°" + informeCaja[0]}
                </Modal.Title>
                <Button
                  variant="outline-danger"
                  onClick={() => cerrarModalInformeCaja()}
                >
                  X
                </Button>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <Row className="d-flex justify-content-center">
                  <h4>Datos de la caja</h4>
                </Row>
                <Row className="d-flex justify-content-center">
                  <ul>
                    <li>
                      {" "}
                      Fecha de Cierre: <b>${informeCaja[9]}</b>
                    </li>
                    <li>
                      {" "}
                      Monto Efectivo: <b>${informeCaja[6]}</b>
                    </li>
                    <li>
                      {" "}
                      Monto Transferencias: <b>${informeCaja[5]}</b>
                    </li>
                    <li>
                      {" "}
                      Monto Cheques: <b>${informeCaja[3]}</b>
                    </li>
                    <li>
                      {" "}
                      Monto Tarjetas: <b>${informeCaja[4]}</b>
                    </li>
                    <li>
                      {" "}
                      Monto Total: <b>${informeCaja[8]}</b>
                    </li>
                    <li>
                      Observación: <b> {informeCaja[7]}</b>
                    </li>
                  </ul>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{ backgroundColor: "#f5be87" }}
                    >
                      <Typography
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <b>Pagos de la caja</b>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: 0 }}>
                      <MUIDataTable
                        ref={containerRef}
                        title={"Pagos caja"}
                        data={historialPagosCaja}
                        columns={columnsPagosCajaActual}
                        options={optionsPagosCajaActual}
                        id="tablaPagosCaja"
                      ></MUIDataTable>
                    </AccordionDetails>
                  </Accordion>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="outline-success"
                  onClick={() => cerrarModalInformeCaja()}
                >
                  Salir
                </Button>
              </Modal.Footer>
            </Modal>
            <h3>Cobros</h3>
            {content !== undefined && content !== "No token provided!" ? (
              <div>
                <Modal
                  show={show}
                  fullscreen={true}
                  onHide={() => setShow(false)}
                >
                  <Modal.Header>
                    <Modal.Title>Usuario: {dataElegido[1]}</Modal.Title>
                    <Button
                      variant="outline-danger"
                      onClick={() => cerrarModal()}
                    >
                      X
                    </Button>
                  </Modal.Header>
                  <Modal.Body style={{ borderColor: "red", borderWidth: 10 }}>
                    <Tabs
                      defaultActiveKey="actual"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      onSelect={(k) => console.log("K:" + k)}
                      style={{ borderColor: "#f9f2ed" }}
                    >
                      <Tab eventKey="actual" title="Actual">
                        <Col>
                          <Row className="d-flex justify-content-center">
                            <h4>Caja Actual</h4>
                          </Row>
                          <Row className="d-flex justify-content-center">
                            <ul>
                              <li>
                                Monto Efectivo:{" "}
                                {cajaActual.montos !== undefined ? (
                                  <b>
                                    {" $" + cajaActual.montos.montoEfectivo}
                                  </b>
                                ) : (
                                  <b>$0</b>
                                )}
                              </li>
                              <li>
                                Monto Transferencias:{" "}
                                {cajaActual.montos !== undefined ? (
                                  <b>
                                    {" $" +
                                      cajaActual.montos.montoTransferencia}
                                  </b>
                                ) : (
                                  <b>$0</b>
                                )}
                              </li>
                              <li>
                                Monto Cheques:{" "}
                                {cajaActual.montos !== undefined ? (
                                  <b>{" $" + cajaActual.montos.montoCheque}</b>
                                ) : (
                                  <b>$0</b>
                                )}
                              </li>
                              <li>
                                Monto Tarjetas:{" "}
                                {cajaActual.montos !== undefined ? (
                                  <b>{" $" + cajaActual.montos.montoTarjeta}</b>
                                ) : (
                                  <b>$0</b>
                                )}
                              </li>
                              <li>
                                Monto Total:{" "}
                                {cajaActual.montos !== undefined ? (
                                  <b>{" $" + cajaActual.montos.montoTotal}</b>
                                ) : (
                                  <b>$0</b>
                                )}
                              </li>
                            </ul>
                          </Row>
                          <Row className="d-flex justify-content-center">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                style={{ backgroundColor: "#f5be87" }}
                              >
                                <Typography
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <b>Pagos</b>
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails style={{ padding: 0 }}>
                                <MUIDataTable
                                  ref={containerRef}
                                  title={"Pagos caja actual"}
                                  data={cajaActual.pagos}
                                  columns={columnsPagosCajaActual}
                                  options={optionsPagosCajaActual}
                                  id="tablaPagosCajaActual"
                                ></MUIDataTable>
                              </AccordionDetails>
                            </Accordion>
                          </Row>
                          <Row
                            className="d-flex justify-content-center"
                            style={{ paddingTop: 10 }}
                          >
                            <textarea
                              id="observacion"
                              placeholder="Observación"
                              rows="5"
                              cols="27"
                            />
                          </Row>

                          <Row
                            className="d-flex justify-content-center"
                            style={{ padding: 10 }}
                          >
                            <Button
                              variant="danger"
                              onClick={() => setShowPregunta(true)}
                            >
                              Cerrar Caja
                            </Button>
                          </Row>
                        </Col>
                      </Tab>
                      <Tab eventKey="historicoCajas" title="Historico Cajas">
                        <MUIDataTable
                          ref={containerRef}
                          title={"Historial de Cajas"}
                          data={historialDeCajas}
                          columns={columnsHistorialDeCajas}
                          options={optionsHistorialDeCajas}
                          id="tablaHistorialDeCajas"
                        ></MUIDataTable>
                      </Tab>
                    </Tabs>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="outline-success"
                      onClick={() => cerrarModal()}
                    >
                      Salir
                    </Button>
                  </Modal.Footer>
                </Modal>
                <Row>
                  <Col>
                    <ListGroup
                      horizontal={"md"}
                      style={{
                        backgroundColor: "#F9F2ED",
                        borderStyle: "hidden",
                      }}
                    >
                      <ListGroup.Item
                        style={{
                          backgroundColor: "#F9F2ED",
                          borderStyle: "hidden",
                        }}
                      >
                        <ListGroup.Item
                          action
                          onClick={chequesClicked}
                          style={{ backgroundColor: "#f5be87" }}
                        >
                          <b>Cheques</b>
                        </ListGroup.Item>
                        <ListGroup.Item
                          onClick={historicoCajasClicked}
                          style={{ backgroundColor: "#f5be87" }}
                        >
                          <b>Historico Cajas</b>
                        </ListGroup.Item>
                      </ListGroup.Item>
                    </ListGroup>
                    <MUIDataTable
                      ref={containerRef}
                      title={"Clientes"}
                      data={empleados}
                      columns={columnsEmpleados}
                      options={options}
                      id="tablaEmpleados"
                    ></MUIDataTable>
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        )}
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};
export default Cobros;
