import React, { useState, useEffect, useRef } from "react";

import CajasService from "../services/cajas.service";
import PagosCuentasCorrientesService from "../services/pagos_cuentas_corrientes.service";
import EventBus from "../common/EventBus";
import { Container, Row, Col, Modal, Button } from "react-bootstrap/";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import MUIDataTable from "mui-datatables";

const dayjs = require("dayjs");

const HistoricoCajas = () => {
  const [content, setContent] = useState("");
  const [responseCode, setResponseCode] = useState("");

  const containerRef = useRef(null);

  // Modal InformeCaja//
  const [tituloModalInformeCaja, setTituloModalInformeCaja] =
    useState("Informe de Caja");
  const [fullscreenInformeCaja, setFullscreenInformeCaja] = useState(true);
  const [showInformeCaja, setShowInformeCaja] = useState(false);
  // Fin Modal InformeCaja//

  const [historicoCajas, setHistoricoCajas] = useState([]);
  const [informeCaja, setInformeCaja] = useState([]);
  const [historialPagosCaja, setHistorialPagosCaja] = useState([]);

  // Modal Comprobante Pago CC//
  const [showComprobantePagoCC, setShowComprobantePagoCC] = useState(false);
  const [tituloModalComprobantePagoCC, setTituloModalComprobantePagoCC] =
    useState("");
  const [dataGetPago, setDataGetPago] = useState(false);
  // Fin Modal Comprobante Pago CC//

  useEffect(() => {
    CajasService.getHistoricoCajas().then(
      (response) => {
        setHistoricoCajas(response.data);
        setResponseCode(response.status);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  const columnsHistoricoCajas = [
    {
      name: "idCaja",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "FechaCierre",
      label: "Fecha de Cierre",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 30 }}>
              {dayjs(value).format("DD/MM/YYYY HH:mm")}
            </Row>
          );
        },
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{"$" + value}</Row>;
        },
      },
    },
    {
      name: "ChequeMonto",
      label: "ChequeMonto",
      options: {
        display: "excluded",
      },
    },
    {
      name: "TarjetaMonto",
      label: "TarjetaMonto",
      options: {
        display: "excluded",
      },
    },
    {
      name: "TransferenciaMonto",
      label: "TransferenciaMonto",
      options: {
        display: "excluded",
      },
    },
    {
      name: "EfectivoMonto",
      label: "EfectivoMonto",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Observaciones",
      label: "Observaciones",
      options: {
        display: "excluded",
      },
    },
    {
      name: "Total", // Lo traigo de nuevo para que se guarde sin el formato y poder mostrarlo después
      label: "Total",
      options: {
        display: "excluded",
      },
    },
    {
      name: "FechaCierre", // Lo traigo de nuevo para que se guarde sin el formato y poder mostrarlo después
      label: "Fecha de Cierre",
      options: {
        display: "excluded",
        customBodyRender: (value, tableMeta, updateValue) => {
          return dayjs(value).format("DD/MM/YYYY HH:mm");
        },
      },
    },
    {
      name: "user.empleado.Nombre",
      label: "Nombre de empleado",
      options: {
        display: "excluded",
      },
    },
    {
      name: "user.empleado.Apellido",
      label: "Nombre de empleado",
      options: {
        display: "excluded",
      },
    },
    {
      name: "user.empleado.Apellido",
      label: "Nombre de empleado",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Row style={{ paddingLeft: 30 }}>
              {tableMeta.rowData[10] + " " + value}
            </Row>
          );
        },
      },
    },
    {
      name: "user.empleado.TelefonoCelular",
      label: "Telefono de empleado",
      options: {
        display: "excluded",
      },
    },
  ];

  const optionsHistoricoCajas = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar caja",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    elevation: 0,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      setInformeCaja(rowData);
      CajasService.getHistorialPagosCaja(rowData[0]).then((response) => {
        console.log(response);
        setHistorialPagosCaja(response.data);
        setShowInformeCaja(true);
      });
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const columnsPagosCaja = [
    {
      name: "idPago",
      label: "ID",
      options: {
        display: "excluded",
      },
    },
    {
      name: "cuentas_corriente.cliente.RazonSocial",
      label: "Razón Social",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Tipo",
      label: "Tipo",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{value}</Row>;
        },
      },
    },
    {
      name: "Total",
      label: "Total",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Row style={{ paddingLeft: 30 }}>{"$" + value}</Row>;
        },
      },
    },
  ];

  const optionsPagosCaja = {
    filterType: "checkbox",
    onRowsDelete: () => {
      return false;
    },
    rowsPerPageOptions: 100,
    selectToolbarPlacement: "replace",
    responsive: "standard",
    searchOpen: true,
    searchAlwaysOpen: true,
    searchPlaceholder: "Buscar pago",
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    download: false,
    print: false,
    fixedHeader: true,
    fixedSelectColumn: true,
    enableNestedDataAccess: ".",
    elevation: 0,
    onSearchClose: () => {
      console.log("Cierra");
    },
    isRowExpandable: (dataIndex, expandedRows) => {
      /*
          if(expandedRows.data.length>1){
            let original = expandedRows.data;
            let aux = expandedRows.data.slice(dataIndex,-1);
            expandedRows.data = aux;
            setMostradas(aux);
            return true;
          }
          else{
            
          }
          //expandedRows.data=[]; // Esto hace que siempre haya uno solo expandido
          return true;
          */
      //if (dataIndex === 3 || dataIndex === 4) return false;

      // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
      if (
        expandedRows.data.length > 8 &&
        expandedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0
      )
        return false;
      return true;
    },
    isRowSelectable: () => {
      return false;
    },
    selectableRows: false,
    rowsExpanded: [],
    onRowClick: async (rowData) => {
      console.log(rowData);
      PagosCuentasCorrientesService.getPago(rowData[0]).then(
        (response) => {
          console.log(response.data);
          setDataGetPago(response.data);
          setTituloModalComprobantePagoCC(response.data.idPago);
          setShowComprobantePagoCC(true);
          //response.data.map((articulo) => console.log(articulo));
          //setLoadingData(true);
        },
        (error) => {
          const _content =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

          //setContent(_content);
          //setLoadingData(true);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    /*
        renderExpandableRow: (rowData, rowMeta) => {
          if(!isTabletOrMobile){
            const colSpan = rowData.length + 1;
            return (
              <TableRow>
                <TableCell colSpan={colSpan} >
                  <Card>{rowData}</Card>
                </TableCell>
              </TableRow>
            );
          }
        },
        */
    renderExpandableRow: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      console.log(curExpanded, allExpanded, rowsExpanded);
    },
  };

  const cerrarModalInformeCaja = () => {
    setShowInformeCaja(false);
  };

  function handleShowComprobantePagoCC(breakpoint) {
    setShowComprobantePagoCC(true);
  }
  function handleShowCerrarGetComprobantePagoCC() {
    setShowComprobantePagoCC(false);
  }

  if (responseCode === 200) {
    return (
      <Container flex>
        <Modal
          show={showComprobantePagoCC}
          fullscreen={true}
          onHide={() => handleShowComprobantePagoCC()}
        >
          <Modal.Header>
            <Modal.Title>
              Comprobante: {tituloModalComprobantePagoCC}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex justify-content-center">
              <Col>
                {dataGetPago.Tipo === "Cheque" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Cheque Fecha Pago:{" "}
                        <b>
                          {dayjs(dataGetPago.ChequeFechaPago).format(
                            "DD/MM/YY"
                          )}
                        </b>
                      </li>
                      <li>
                        Cheque Banco Emisor:{" "}
                        <b>{dataGetPago.ChequeBancoEmisor}</b>
                      </li>
                      <li>
                        Cheque Fecha Vencimiento:{" "}
                        <b>
                          {dayjs(dataGetPago.ChequeFechaVencimiento).format(
                            "DD/MM/YY"
                          )}
                        </b>
                      </li>
                      <li>
                        Número de Cheque: <b>{dataGetPago.ChequeNroCheque}</b>
                      </li>
                      <li>
                        Cheque Monto: <b>$ {dataGetPago.ChequeMonto}</b>
                      </li>
                      <li>
                        Cheque Digital:{" "}
                        <b>{dataGetPago.ChequeDigital ? "Si" : "No"}</b>
                      </li>
                      <li>
                        Observaciones: <b>{dataGetPago.Observaciones}</b>
                      </li>
                    </ul>
                  </div>
                ) : dataGetPago.Tipo === "Efectivo" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Efectivo Fecha Pago:{" "}
                        <b>
                          {dayjs(dataGetPago.EfectivoFechaPago).format(
                            "DD/MM/YY"
                          )}
                        </b>
                      </li>
                      <li>
                        Efectivo Monto: <b>$ {dataGetPago.EfectivoMonto}</b>
                      </li>
                      <li>
                        Observaciones: <b>{dataGetPago.Observaciones}</b>
                      </li>
                    </ul>
                  </div>
                ) : dataGetPago.Tipo === "Tarjeta" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Tarjeta Fecha Pago:{" "}
                        <b>
                          {dayjs(dataGetPago.TarjetaFechaPago).format(
                            "DD/MM/YY"
                          )}
                        </b>
                      </li>
                      <li>
                        Tarjeta Nro Comprobante:{" "}
                        <b>{dataGetPago.TarjetaNroComprobante}</b>
                      </li>
                      <li>
                        Tarjeta Monto: <b>$ {dataGetPago.TarjetaMonto}</b>
                      </li>
                      <li>
                        Tipo de Tarjeta: <b>{dataGetPago.TipoDeTarjeta}</b>
                      </li>
                      <li>
                        Observaciones: <b>{dataGetPago.Observaciones}</b>
                      </li>
                    </ul>
                  </div>
                ) : dataGetPago.Tipo === "Transferencia" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Transferencia Fecha Pago:{" "}
                        <b>
                          {dayjs(dataGetPago.TransferenciaFechaPago).format(
                            "DD/MM/YY"
                          )}
                        </b>
                      </li>
                      <li>
                        Transferencia Cuenta Destino:{" "}
                        <b>{dataGetPago.TransferenciaCuentaDestino}</b>
                      </li>
                      <li>
                        Transferencia Nro Comprobante:{" "}
                        <b>{dataGetPago.TransferenciaNroComprobante}</b>
                      </li>
                      <li>
                        Transferencia Monto:{" "}
                        <b>$ {dataGetPago.TransferenciaMonto}</b>
                      </li>
                      <li>
                        Observaciones: <b>{dataGetPago.Observaciones}</b>
                      </li>
                    </ul>
                  </div>
                ) : dataGetPago.Tipo === "Descuento" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Descuento Monto: <b>$ {dataGetPago.Total}</b>
                      </li>
                      <li>
                        Observaciones Descuento:{" "}
                        <b>{dataGetPago.ObservacionesDescuento}</b>
                      </li>
                    </ul>
                  </div>
                ) : dataGetPago.Tipo === "Nota De Débito" ? (
                  <div>
                    <ul>
                      <li>
                        Fecha:{" "}
                        <b>
                          {dayjs(dataGetPago.Fecha).format("DD/MM/YY HH:mm:ss")}
                        </b>
                      </li>
                      <li>
                        Nota De Débito Monto: <b>$ {dataGetPago.Total}</b>
                      </li>
                      <li>
                        Observaciones Nota de débito:{" "}
                        <b>{dataGetPago.ObservacionesNDD}</b>
                      </li>
                    </ul>
                  </div>
                ) : null}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              onClick={() => handleShowCerrarGetComprobantePagoCC()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showInformeCaja}
          fullscreen={true}
          onHide={() => setShowInformeCaja(false)}
        >
          <Modal.Header>
            <Modal.Title>
              {tituloModalInformeCaja + " N°" + informeCaja[0]}
            </Modal.Title>
            <Button
              variant="outline-danger"
              onClick={() => cerrarModalInformeCaja()}
            >
              X
            </Button>
          </Modal.Header>
          <Modal.Body>
            {" "}
            <Row className="d-flex justify-content-center">
              <h4>Datos de la caja</h4>
            </Row>
            <Row className="d-flex justify-content-center">
              <ul>
                <li>
                  {" "}
                  Fecha de Cierre: <b>${informeCaja[9]}</b>
                </li>
                <li>
                  {" "}
                  Monto Efectivo: <b>${informeCaja[6]}</b>
                </li>
                <li>
                  {" "}
                  Monto Transferencias: <b>${informeCaja[5]}</b>
                </li>
                <li>
                  {" "}
                  Monto Cheques: <b>${informeCaja[3]}</b>
                </li>
                <li>
                  {" "}
                  Monto Tarjetas: <b>${informeCaja[4]}</b>
                </li>
                <li>
                  {" "}
                  Monto Total: <b>${informeCaja[8]}</b>
                </li>
                <li>
                  Observación: <b> {informeCaja[7]}</b>
                </li>
              </ul>
            </Row>
            <Row className="d-flex justify-content-center">
              <h4>Datos empleado</h4>
            </Row>
            <Row className="d-flex justify-content-center">
              <ul>
                <li>
                  Nombre: <b>{informeCaja[10] + " " + informeCaja[11]}</b>
                </li>
                <li>
                  Teléfono: <b>{informeCaja[13]}</b>
                </li>
              </ul>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ backgroundColor: "#f5be87" }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <b>Pagos de la caja</b>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                  <MUIDataTable
                    ref={containerRef}
                    title={"Pagos caja"}
                    data={historialPagosCaja}
                    columns={columnsPagosCaja}
                    options={optionsPagosCaja}
                    id="tablaPagosCaja"
                  ></MUIDataTable>
                </AccordionDetails>
              </Accordion>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-success"
              onClick={() => cerrarModalInformeCaja()}
            >
              Salir
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col>
            <h3>Historico de Cajas</h3>
            <MUIDataTable
              ref={containerRef}
              title={"Historico de Cajas"}
              data={historicoCajas}
              columns={columnsHistoricoCajas}
              options={optionsHistoricoCajas}
              id="tablaHistoricoCajas"
            ></MUIDataTable>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{content}</h3>
        </header>
      </div>
    );
  }
};
export default HistoricoCajas;
