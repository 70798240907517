import axios from "axios";
import authHeader from "./auth-header";
require('dotenv').config();

const API_URL =  process.env.REACT_APP_URL +"/api/cc/";//http://localhost:8080/api/cli/";

const getTodos = async () => {
    const resultado = await axios.get(API_URL + "all/", { headers: authHeader() });
    return resultado;
};
const getDeudoras = async () => {
    const resultado = await axios.get(API_URL + "deudoras/", { headers: authHeader() });
    return resultado;
};

const getAtrasadas = async () => {
    const resultado = await axios.get(API_URL + "atrasadas/", { headers: authHeader() });
    return resultado;
};
/*
const getUserBoard = () => {
  return axios.get(API_URL + "user", { headers: authHeader() });
};

const getModeratorBoard = () => {
  return axios.get(API_URL + "mod", { headers: authHeader() });
};

const getAdminBoard = () => {
  return axios.get(API_URL + "admin", { headers: authHeader() });
};
*/
const CCService = {
    getTodos,
    getDeudoras,
    getAtrasadas
};

export default CCService;