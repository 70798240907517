import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";


import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { CartProvider } from "react-use-cart";

ReactDOM.render(
  <BrowserRouter>
      <CartProvider>
       <App />
      </CartProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
//serviceWorker.register();