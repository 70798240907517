import React, { useState, useEffect } from "react";

import UserService from "../services/user.service";
import EventBus from "../common/EventBus";
import Articulos from "./Articulo"; 

import {Container, Row, Col} from 'react-bootstrap/';

const BoardUser = () => {
  const [content, setContent] = useState("");

  useEffect(() => {
    UserService.getUserBoard().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setContent(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }, []);

  return (
    <Container>
        <h3>Lista de Artículos</h3>
          {
            content !== undefined && content !== 'No token provided!'? 
            <Articulos>{0}</Articulos>
            :
            console.log("")
          } 
    </Container>
  );
};

export default BoardUser;
