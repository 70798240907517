import axios from "axios";
import authHeader from "./auth-header";
require("dotenv").config();

const getComprobantes = async (id) => {
  let endpoints = [
    process.env.REACT_APP_URL + "/api/pp/search/" + id,
    process.env.REACT_APP_URL + "/api/factura/search/" + id,
    process.env.REACT_APP_URL + "/api/devoluciones/search/" + id,
  ];
  let response = [];
  await Promise.all(
    endpoints.map((endpoint) => axios.get(endpoint, { headers: authHeader() }))
  ).then(
    (results) => {
      const pps = results[0].data;
      const ffs = results[1].data;
      const ncs = results[2].data;
      response.data = pps;
      ffs.forEach((element) => {
        response.data.push(element);
      });
      ncs.forEach((element) => {
        response.data.push(element);
      });
      //response.data = { ...pps, ...ffs};
    },
    (error) => {
      const _content =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      //setContent(_content);
    }
  );
  return response;
};

const ComprobantesService = {
  getComprobantes,
};

export default ComprobantesService;
